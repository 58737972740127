import { Component, OnInit }    from '@angular/core'
import { SubmissionService }    from './../services/submission.service'
import { UserService }          from './../services/user.service'
import { VtService }            from './../services/vt.service'
import { EventService }         from './../services/event.service'
import { UtilitiesService }     from './../services/utilities.service'
import { NotesService }         from './../services/notes.service'

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss'],
})

export class SubmissionComponent implements OnInit {

  submission
  userRole
  videoTestimony
  active
  notes

  constructor(
    private submissionService: SubmissionService,
    private userService: UserService,
    private vtService: VtService,
    private eventService: EventService,
    private utilitiesService: UtilitiesService,
    private notesService: NotesService,
  ) {}

  refreshSubmissionData(switchToTab) {
    this.submissionService
      .getSubmission()
      .subscribe(
        (response: any) => {
          this.submission = response.data

          let assessments = []

          this.submission.assessments.forEach(assessment => {
            assessments.push(assessment)            
          })

          this.submission.assessments = assessments

          this.submission.credentials.forEach((credential, credentialIndex) => {
            this.submission.credentials[credentialIndex].name = this.utilitiesService.removeLevelFromCredential(
              credential.name,
              credential.level_name,
            )
          })

          if (switchToTab) this.active = switchToTab
        }
      )
  }

  refreshVideoTestimonyData() {
    this.vtService.trackSessions().subscribe(data => {
      this.videoTestimony = data
    })
  }

  refreshNotesData() {
    this.notesService.getNotes().subscribe((notes: any) => {
      this.notes = notes.data
    })
  }

  ngOnInit() {

    this.userRole = this.userService.getUserRole()

    this.refreshSubmissionData(null)
    this.refreshVideoTestimonyData()
    this.refreshNotesData()

    this.eventService.onRefreshSubmissionsData().subscribe(switchToTab => {
      this.refreshSubmissionData(switchToTab)
    })

    this.eventService.onRefreshVideoTestimonyData().subscribe(switchToTab => {
      this.refreshVideoTestimonyData()
    })

  }

}
