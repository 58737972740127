import { Injectable } from '@angular/core'

declare let dataLayer

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  addEvent(event) {
    return dataLayer.push(event)
  }

  constructor() { }
}
