import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { ApInterceptor } from './ap.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AcceptAssignmentsModalComponent } from './submissions/accept-assignments-modal/accept-assignments-modal.component';
import { ActionRequiredCardComponent } from './submissions/action-required-card/action-required-card.component';
import { AppComponent } from './app.component';
import { AssignAssessorComponent } from './submission/manage-submission/assign-assessor/assign-assessor.component';
import { CreateAssessmentComponent } from './submission/create-assessment/create-assessment.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { ManageSubmissionComponent } from './submission/manage-submission/manage-submission.component';
import { NavComponent } from './nav/nav.component';
import { SubmissionComponent } from './submission/submission.component';
import { SubmissionDetailsComponent } from './submission/submission-details/submission-details.component';
import { SubmissionsComponent } from './submissions/submissions.component';
import { SubmissionsTableComponent } from './submissions/submissions-table/submissions-table.component';
import { ToastComponent } from './toast/toast.component';
import { ViewAssessmentComponent } from './submission/view-assessment/view-assessment.component';
import { WrittenExampleComponent } from './submission/written-example/written-example.component';
import { LoaderComponent } from './loader/loader.component';
import { AssessorDetailsComponent } from './submission/manage-submission/assessor-details/assessor-details.component';
import { VideoTestimonyComponent } from './submission/video-testimony/video-testimony.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { NotesComponent } from './submission/notes/notes.component';
import { InvoiceComponent } from './submissions/submissions-table/invoice/invoice.component';
import { DatePickerComponent } from './submissions/submissions-table/date-picker/date-picker.component';

@NgModule({
  declarations: [
    AcceptAssignmentsModalComponent,
    ActionRequiredCardComponent,
    AppComponent,
    AssignAssessorComponent,
    CreateAssessmentComponent,
    FooterComponent,
    LoginComponent,
    ManageSubmissionComponent,
    NavComponent,
    SubmissionComponent,
    SubmissionDetailsComponent,
    SubmissionsComponent,
    SubmissionsTableComponent,
    ToastComponent,
    ViewAssessmentComponent,
    WrittenExampleComponent,
    LoaderComponent,
    AssessorDetailsComponent,
    VideoTestimonyComponent,
    PasswordResetComponent,
    NotesComponent,
    InvoiceComponent,
    DatePickerComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
