import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { VtService } from './../../services/vt.service'
import { ToastService } from './../../services/toast.service'
import { EventService } from './../../services/event.service'

@Component({
  selector: 'app-video-testimony',
  templateUrl: './video-testimony.component.html',
  styleUrls: ['./video-testimony.component.scss'],
})

export class VideoTestimonyComponent implements OnInit {

  active
  lastCompletedTestimony
  videoUrls = []
  videoTestimonyLog
  @Input() nid
  @Input() videoTestimony
  @Input() userRole
  @Input() isCluster

  constructor(
    private vtService: VtService,
    private toastService: ToastService,
    private eventService: EventService,
  ) { }

  createVtSessionWithQuestions = (getQuestionsResponse) => {
    this.vtService.createSession(getQuestionsResponse).subscribe(
        (createSessionResponse: any) => {
            this.vtService.trackSessions().subscribe(
                (trackSessionsResponse: any) => {
                    this.toastService.show(
                        {
                            body: createSessionResponse.message
                        }
                    )
                    setTimeout(() => {
                        this.eventService.refreshVideoTestimonyData()
                    }, 2000)
                }
            )
        }
    )}

  createVtSession =  () => {
    if (this.isCluster) {
      this.vtService.getClusterQuestions(this.nid).subscribe(
        getQuestionsResponse => {
          this.createVtSessionWithQuestions(getQuestionsResponse)
        }
      )
    } else {
      this.vtService.getQuestions(this.nid).subscribe(
        getQuestionsResponse => {
          this.createVtSessionWithQuestions(getQuestionsResponse)
        }
      )
    }
  }

  createVideoTestimonyLog = () => {
    let sortedLog = this.videoTestimony.sessions.concat(
      this.videoTestimony.errors
    ).sort(
      (a, b) => {
        return a.created_at < b.create_at  ?  1 :
               a.created_at > b.created_at ? -1 : 0
      }
    )

    let removeSessions = false
    let filteredLog = []

    sortedLog.forEach(session => {
      removeSessions = !session.error

      if (!session.error || (session.error && !removeSessions)) {
        filteredLog.push(session)
      }
    })

    return filteredLog
  }

  videoEnd = () => {
  	if (this.active < this.videoUrls.length - 1) this.active++
  }

  ngOnChanges() {
    this.lastCompletedTestimony = this.videoTestimony.sessions.slice().find(
      vt => {
        return vt.status === 'COMPLETED' &&
               vt.questions[0].uuid
      }
    )

    this.videoTestimonyLog = this.createVideoTestimonyLog()

    if (this.lastCompletedTestimony) {
      this.lastCompletedTestimony.questions.forEach((question, index) => {
        this.vtService.getStreamUrl(question.uuid).subscribe((data: any) => {
          this.videoUrls[index] = data.url
        })
      })
    }
  }

  ngOnInit(): void {}

}