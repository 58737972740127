import { Component, Input, OnInit } from '@angular/core'
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays'
import { BreakpointObserver } from '@angular/cdk/layout'
import { UserService } from './../../services/user.service'

@Component({
  selector: 'app-action-required-card',
  templateUrl: './action-required-card.component.html',
  styleUrls: ['./action-required-card.component.scss'],
})

export class ActionRequiredCardComponent implements OnInit {

  @Input() actionRequiredSubmissions
  pageSize = 1
  page = 1

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
  ) {
    let breakpoint = {
      xl: '(min-width: 1200px)',
      lg: '(min-width: 992px)',
      md: '(min-width: 768px)',
    }
    breakpointObserver.observe([
      breakpoint.xl,
      breakpoint.lg,
      breakpoint.md,
    ]).subscribe(result => {
      for (const [key, value] of Object.entries(result.breakpoints)) {
        if (value) {
          switch (key) {
            case breakpoint.xl:
              this.pageSize = 8
              break
            case breakpoint.lg:
              this.pageSize = 7
              break
            case breakpoint.md:
              this.pageSize = 5
              break
          this.page = 1
          }
          return
        }
      }
    })
  }

  calculateDaysToDueDate(submission) {
    if (submission.assessors.length &&
        submission.assessors.slice(-1).pop().due_date !== undefined &&
        submission.assessors.slice(-1).pop().due_date !== null
    ) {
      
      let dueDate = new Date(submission.assessors.slice(-1).pop().due_date)
      dueDate.setHours(23, 59, 59, 999)

      let now = new Date()
      now.setHours(0, 0, 0, 0)

      return differenceInBusinessDays(
        dueDate,
        now,
      )
    }

    return Infinity
  }

  daysLeftClass(submission) {
    if (submission.assessors.length &&
        submission.assessors.slice(-1).pop().due_date !== undefined &&
        submission.assessors.slice(-1).pop().due_date !== null
    ) {
      
      let dueDate = new Date(submission.assessors.slice(-1).pop().due_date)
      dueDate.setHours(23, 59, 59, 999)

      let now = new Date()
      now.setHours(0, 0, 0, 0)

      let days = differenceInBusinessDays(dueDate, now)
      if (days < 1) days = 0

      if (days < 8 && this.userService.getUserRole() !== 'COORDINATOR') {
        return 'alert days-left-' + days
      } else {
        return ''
      }
    }

    return ''
  }

  ngOnInit() {
  	// this.actionRequiredSubmissions.pending_action =
  	//   this.actionRequiredSubmissions.pending_action
  	//     .toUpperCase().replaceAll('_', ' ')
  }

}
