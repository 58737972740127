import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core'

import { FormGroup, FormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { UserService } from './../../services/user.service'
import { AnalyticsService } from './../../services/analytics.service'

@Component({
  selector: 'app-submissions-table',
  templateUrl: './submissions-table.component.html',
  styleUrls: ['./submissions-table.component.scss'],
})
export class SubmissionsTableComponent implements OnInit {

  @Input() submissionsTableColumnHeadings
  @Input() submissionsTableData
  @Input() datepicker
  @ViewChild('tableElement') tableElement

  filteredSubmissions
  sortedColumn
  toggleSortOrder
  activeAssessors = []
  allAssessors
  activeTags = []
  allTags
  submissionsTablePage = 1
  submissionsTablePageSize = 50
  searchTerms = []
  userRole
  filteredBy = []
  dateRange

  constructor(
    private router: Router,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {
  }

  tableFilter = new FormGroup({
    search: new FormControl(''),
  })

  filterDateRange(dateRange) {
    if (dateRange) {

      this.dateRange = [
        new Date([
          dateRange[0].year,
          dateRange[0].month,
          dateRange[0].day,
        ].join('/')).getTime(),
      ]

      if (dateRange[1]) {
        this.dateRange.push(
          new Date([
            dateRange[1].year,
            dateRange[1].month,
            dateRange[1].day,
          ].join('/')).getTime() + ( 1000 * 60 * 60 * 24 - 1 )
        )
      } else {
        this.dateRange.push(
          this.dateRange[0] + ( 1000 * 60 * 60 * 24 - 1 )
        )
      }

    } else {
      this.dateRange = null
    }

    this.filterRows()
  }

  ngOnChanges() {
    this.filteredSubmissions = this.submissionsTableData
  }

  sortColumn(index) {
    this.submissionsTableData.sort((a, b) => {
      if (!a.data[index]) return this.toggleSortOrder ? -1 :  1
      if (!b.data[index]) return this.toggleSortOrder ?  1 : -1
      return (a.data[index]) < (b.data[index]) ? -1 :
             (a.data[index]) > (b.data[index]) ?  1 : 0
    })

    if (this.sortedColumn === index) {
      if (this.toggleSortOrder) {
        this.submissionsTableData.reverse()
      }
      this.toggleSortOrder = !this.toggleSortOrder
    } else {
      this.sortedColumn = index
      this.toggleSortOrder = true
    }

    this.filterRows()
  }

  displayAsDate(submission, column) {
    if (submission.dateFormat) {
      return submission.dateFormat.some(col => col === column)
    }

    return false
  }

  filterRows() {
    this.searchTerms = this.tableFilter
                         .get('search').value
                         .toLowerCase()
                         .split(' ')
                         .filter(
                           term => term !== ""
                         )

    this.filteredBy = this.activeAssessors.map(
      assessor => {
        return assessor.name
      }
    ).concat(this.activeTags)

    if (this.dateRange) {
      let fromDate = new Date(this.dateRange[0]).toLocaleDateString('en-AU')
      let toDate   = new Date(this.dateRange[1]).toLocaleDateString('en-AU')

      this.filteredBy.unshift(fromDate + (fromDate === toDate ? '' : ' – ' + toDate))
    }

    this.submissionsTablePage = 1

    return this.filteredSubmissions = this.submissionsTableData.filter(
      submission => {
        if (this.searchTerms.length !== 0) {
          let row = submission.data.slice(0, 5).join(' ')
          return this.searchTerms.every(searchTerm => {
            return row.toLowerCase().includes(searchTerm)
          })
        } else {
          return true
        }
      }
    ).filter(
      submission => {
        let dateSubmitted = new Date(submission.data[6]).getTime()
        let dueDate = new Date(submission.data[7]).getTime()

        if (this.dateRange && this.dateRange.length) {
          if (dateSubmitted > this.dateRange[0] && dateSubmitted < this.dateRange[1])
          return submission

          if (dueDate > this.dateRange[0] && dueDate < this.dateRange[1])
          return submission
        } else {
          return submission
        }
      }
    ).filter(
      submission => {
        if (this.activeTags.length) {
          if (submission.tags.some(tag => this.activeTags.includes(tag))) {
            return submission
          }
        } else {
          return submission
        }
      }
    ).filter(
      submission => {
        let assessorIds = this.activeAssessors.map(assessor => {
          return assessor.id
        })

        if (this.activeAssessors.length) {
          if (submission.assessors.some(
            assessor => assessorIds.includes(assessor.id)
          )) {
            return submission
          }
        } else {
          return submission
        }
      }
    )
  }

  getUniqueTags() {
    let uniqueTags = new Set()

    this.submissionsTableData.forEach(submission => {
      submission.tags.forEach(tag => {
        uniqueTags.add(tag)
      })
    })

    return Array.from(uniqueTags).sort()
  }

  getUniqueAssessors() {
    let uniqueAssessors = new Set()

    this.submissionsTableData.forEach(submission => {
      submission.assessors.forEach(assessor => {
        uniqueAssessors.add(
          JSON.stringify({
            name: assessor.name,
            id: assessor.id,
          })
        )
      })
    })
    
    return Array.from(uniqueAssessors).sort().map((assessor: string) => {
      return JSON.parse(assessor)
    })
  }

  ngOnInit(): void {
    let gaSearchDebounce

    this.tableFilter.get('search').valueChanges.subscribe(
      () => {
        clearTimeout(gaSearchDebounce)
        gaSearchDebounce = setTimeout(() => {
          this.analyticsService.addEvent({
            event: 'search',
            searchTerm: this.tableFilter.get('search').value,
          })
        }, 1000)
        this.filterRows()
      }
    )

    this.userRole = this.userService.getUserRole()

    this.allTags = this.getUniqueTags()
    this.allAssessors = this.getUniqueAssessors()
  }

  pageChange(event) {
    this.tableElement.nativeElement.scrollIntoView({
      behavior: "smooth"
    })
    // this.router.navigate(['submissions/' + this.submissionsTablePage])
  }

  toggleAssessor(assessor) {
    let index = this.activeAssessors.findIndex(activeAssessor => {
      return activeAssessor.id === assessor.id
    })

    if (index + 1) {
      this.activeAssessors.splice(index, 1)
    } else {
      this.activeAssessors.push(assessor)
    }

    this.filterRows()
  }

  toggleTag(tag) {
    let index = this.activeTags.indexOf(tag)
    if (index + 1) {
      this.activeTags.splice(index, 1)
    } else {
      this.activeTags.push(tag)
    }

    this.filterRows()
  }

}
