import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UtilitiesService {

 // Deprecated this function return name as it is
  removeLevelFromCredential = (credential, level) => {
     return credential;
    //return credential.replaceAll(level, '').trim()
  }

  getOutcomeClass = outcome => {
    if (outcome === 'satisfied')
      return 'ap-text-blue'

    if (outcome === 'satisfied_lower_level')
      return 'ap-text-blue'

    if (outcome === 'not_satisfied')
      return 'ap-text-red'

    if (outcome === 'testimony_fail')
      return 'ap-text-yellow'

    if (outcome === 'resubmission')
      return 'ap-text-yellow'

    return 'ap-text-green'
  }

  formatOutcome = outcome => {
    return outcome.charAt(0).toUpperCase() + outcome.slice(1).replaceAll('_', ' ')
  }

  constructor() { }
}
