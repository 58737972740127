<div
  *ngIf="monthsWithInvoice.size"
  ngbDropdown
>
  <div
    ngbDropdown
    class="d-inline-block"
  >
    <button
      id="dropdownBasic1"
      ngbDropdownToggle
    >
      Download invoice
    </button>
    <div
      ngbDropdownMenu
      aria-labelledby="dropdownBasic1"
    >
      <button
        ngbDropdownItem
        *ngFor="let month of monthsWithInvoice"
        (click)="generateInvoice(month)"
      >
        {{ month }}
      </button>
    </div>
  </div>
</div>