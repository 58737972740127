<div class="row">
  <div class="col-md-4">
    <form
      [formGroup]="assignAssessorForm"
      (ngSubmit)="openAssignAssessorConfirmationModal(content)"
    >

      <div class="form-group">

        <label for="selectAssessor">
          Select an {{ role }} assessor
        </label>
        
        <select
          class="custom-select"
          formControlName="selectAssessor"
          (focus)="assignAssessorForm.get('selectAssessor').markAsUntouched()"
          [ngClass]="
            {'is-invalid':
              assignAssessorForm.get('selectAssessor').invalid && assignAssessorForm.get('selectAssessor').touched
            }"
        >
          <option
            selected
            disabled
          >
          </option>
          
          <option
            *ngFor="let assessor of assessors"
            [ngValue]="assessor"
            [disabled]="!!oppositeAssessor && assessor.id === oppositeAssessor.id"
          >
            {{ assessor.name }}
          </option>

        </select>

        <p class="invalid-feedback">
          Please select an assessor.
        </p>

      </div>

      <button
        type="submit"
        class="green"
      >
        Assign {{ role }} assessor
      </button>

    </form>
  </div>
</div>

<ng-template
  #content
  let-c="close"
  let-d="dismiss"
>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title"
    >
      Assign {{ role }} assessor
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Are you sure you want to assign
      <span class="ap-text-blue">
        {{ assignAssessorForm.get('selectAssessor').value.name }}
      </span>
      as the
      <span class="ap-text-blue">
        {{ role }}
      </span>
      assessor?
    </p>
  </div>
  <div class="modal-footer">
    <button
      class="red secondary"
      (click)="d()"
    >
      No
    </button>
    <button
      ngbAutofocus
      class="green"
      (click)="c()"
    >
      Yes
    </button>
  </div>
</ng-template>