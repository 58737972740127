<ng-container *ngIf="submission">
  <div class="container">
    <app-submission-details
      [submissionData]="submission"
    ></app-submission-details>
    <div class="row">
      <div class="col">
        <ul ngbNav #nav="ngbNav" class="nav-tabs mb-5" [(activeId)]="active">
          <li [ngbNavItem]="1" *ngIf="userRole === 'COORDINATOR'">
            <a ngbNavLink>Assessors</a>
            <ng-template ngbNavContent>
              <app-manage-submission
                [submissionData]="submission"
              >
              </app-manage-submission>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" *ngIf="submission.pending_action === 'provide_outcome'">
            <a ngbNavLink>Create assessment</a>
            <ng-template ngbNavContent>
              <app-create-assessment
                [credentials]="submission.credentials"
                [writtenExamples]="submission.examples"
              ></app-create-assessment>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" *ngIf="submission.assessments.length">
            <a ngbNavLink>Assessments</a>
            <ng-template ngbNavContent>
              <app-view-assessment
                [submissionData]="submission"
              ></app-view-assessment>
            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink>Video testimony</a>
            <ng-template ngbNavContent>
              <app-video-testimony
                *ngIf="videoTestimony"
                [videoTestimony]="videoTestimony"
                [nid]="submission.credentials[0].nid"
                [userRole]="userRole"
                [isCluster]="submission.cluster"
              >
              </app-video-testimony>
            </ng-template>
          </li>
          <li [ngbNavItem]="5">
            <a ngbNavLink>Written examples</a>
            <ng-template ngbNavContent>
              <app-written-example
                [writtenExamplesData]="submission.examples"
                [credentialsData]="submission.credentials"
              ></app-written-example>
            </ng-template>
          </li>
          <li
            [ngbNavItem]="6"
            *ngIf="notes && notes.length || userRole === 'COORDINATOR'"
          >
            <a ngbNavLink>Notes</a>
            <ng-template ngbNavContent>
              <app-notes
                [notesData]="notes"
                (submittedNotes)="refreshNotesData()"
              ></app-notes>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="!submission">
</app-loader>
