import {
  NgModule,
} from '@angular/core'

import {
  Routes,
  RouterModule,
} from '@angular/router'

import {
  LoginComponent
} from './login/login.component'

import {
  SubmissionsComponent
} from './submissions/submissions.component'

import {
  SubmissionComponent
} from './submission/submission.component'

import {
  PasswordResetComponent
} from './password-reset/password-reset.component'

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'password-reset/:uuid', component: PasswordResetComponent },
  { path: 'submissions', component: SubmissionsComponent },
  { path: 'submission/:uuid', component: SubmissionComponent },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {}
