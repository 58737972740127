<form class="form-inline">
  <div class="form-group hidden">
    <div class="input-group">
      <input
        name="datepicker"
        class="form-control"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="1"
        [dayTemplate]="t"
        outsideDays="hidden"
        [startDate]="fromDate!"
        tabindex="-1"
        [footerTemplate]="footerTemplate"
      >
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
      <ng-template #footerTemplate>
        <hr class="my-0">
        <button class="yellow secondary btn-sm m-2 float-left" (click)="datepickerClear(); datepicker.close()">Clear</button>
        <button class="grey secondary btn-sm m-2 float-right" (click)="datepicker.close()">Close</button>
      </ng-template>
    </div>
  </div>
  <div class="form-group">
      <div class="input-group-append">
        <button class="green secondary dropdown-toggle" (click)="datepicker.toggle()" type="button">Date</button>
      </div>
  </div>
</form>
