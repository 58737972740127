import {
  Component,
  Input,
} from '@angular/core'

import { FileService } from './../../services/file.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router'
import {
  SubmissionService,
} from './../../services/submission.service'

import {
  ToastService
} from './../../services/toast.service'

import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms'

import {
  EventService
} from './../../services/event.service'

import {
  UtilitiesService
} from './../../services/utilities.service'

import {
  UserService
} from './../../services/user.service'

@Component({
  selector: 'app-submission-details',
  templateUrl: './submission-details.component.html',
  styleUrls: ['./submission-details.component.scss'],
})

export class SubmissionDetailsComponent {

  @Input() submissionData
  private fileErrorInstructions: string = 'You are reporting a submission file error to the candidate. Please select the relevant file you are reporting the error for, then add a description of the file error in the space below.'
  testimonyFailForm = new FormGroup({
    submissionIssue: new FormControl(''),
    notes: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
    signature: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
  })

  constructor(
    private fileService: FileService,
    private modalService: NgbModal,
    private submissionService: SubmissionService,
    private toastService: ToastService,
    private eventService: EventService,
    public userService: UserService,
    public utilitiesService: UtilitiesService,
    private router: Router,
  ) {}

  openRubricMain() {
    if (this.submissionData.credentials[0].is_cluster_rubric === true) {
      this.openRubricForCluster();
      console.log ("calling cluster", this.submissionData.credentials[0].cluster_rubric_vid );
    } else {
      this.openRubricForCredential();
      console.log ("calling credential", this.submissionData.credentials[0].cluster_rubric_vid );
    }
  }

  openRubricForCredential() {
    this.fileService
      .downloadCredentialLevelRubric(
        this.submissionData.credentials[0].id
      )
      .subscribe((data: any) => {
        window.open(data.url)
      })
  }

  openRubricForCluster() {
    this.fileService
      .downloadClusterRubric(
        this.submissionData.credentials[0].cluster_rubric_vid
      )
      .subscribe((data: any) => {
        window.open(data.url)
      })
  }

  open(content) {
    this.modalService.open(content).result.then(
      result => {},
      () => {}
    )
  }

  archiveEverything() {
    this.submissionData.assessments.forEach(assessment => {
      if (!assessment.is_archived) {
        this.submissionService.updateAssessmentStatus(
          assessment.id,
          'archived',
          'archive reason',
          'signature',
        ).subscribe(response => {
          this.toastService.show({
            body: 'Outcome has been successfully archived.',
          })
          this.eventService.refreshSubmissionsData()
        })
      }
    })
  }

  onSubmit() {
    this.testimonyFailForm.controls.notes.markAsTouched()
    this.testimonyFailForm.controls.signature.markAsTouched()

    this.testimonyFailForm.controls.submissionIssue.setErrors(null)

    if (this.testimonyFailForm.controls.submissionIssue.value === '') {
      this.testimonyFailForm.controls.submissionIssue.setErrors({
        incorrect: true,
      })
    }

    if (this.testimonyFailForm.status === "VALID") {

      this.submissionService.createAssessment(
        'testimony_fail',
        [this.testimonyFailForm.controls.submissionIssue.value],
        this.testimonyFailForm.controls.notes.value,
        null,
        this.testimonyFailForm.controls.signature.value,
        this.submissionData.credentials[0].id,
      ).subscribe(data => {
        this.modalService.dismissAll()
        this.toastService.show({
          body: 'File error report has been successfully submitted.',
        })
        this.eventService.refreshSubmissionsData(3)
        this.router.navigate(['/submissions'])
      })
    }
  }

  sendOutcome() {
    this.submissionService.sendOutcome().subscribe(
      (response: any) => {
        this.toastService.show({
          body: response.message,
        })
        this.eventService.refreshSubmissionsData(1)
      }
    )
  }

}
