import { Component, Input, } from '@angular/core'
import { NgbModal, } from '@ng-bootstrap/ng-bootstrap'
import { SubmissionService } from './../../../services/submission.service'
import { ToastService } from './../../../services/toast.service'
import { EventService } from './../../../services/event.service'
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms'
import { UtilitiesService } from './../../../services/utilities.service'

@Component({
  selector: 'app-assessor-details',
  templateUrl: './assessor-details.component.html',
  styleUrls: ['./assessor-details.component.scss'],
})

export class AssessorDetailsComponent {

  @Input() assessor
  @Input() assessments
  @Input() submissionData
  archiveOutcomeForm = new FormGroup({
    archiveReason: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
    signature: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
  })

  constructor(
    private modalService: NgbModal,
    private submissionService: SubmissionService,
    private toastService: ToastService,
    private eventService: EventService,
    public utilitiesService: UtilitiesService,
  ) {}

  open(content) {
    this.modalService.open(content).result.then(
      result => {},
      () => {}
    )
  }

  onSubmit() {
    this.archiveOutcomeForm.controls.archiveReason.markAsTouched()
    this.archiveOutcomeForm.controls.signature.markAsTouched()

    if (this.archiveOutcomeForm.status === "VALID") {
      this.modalService.dismissAll()

      this.assessments.forEach(assessment => {
        this.submissionService.updateAssessmentStatus(
          assessment.id,
          'archived',
          this.archiveOutcomeForm.get('archiveReason').value,
          this.archiveOutcomeForm.get('signature').value,
        ).subscribe(response => {
          this.toastService.show({
            body: 'Outcome has been successfully archived.',
          })
          this.eventService.refreshSubmissionsData()
        })
      })
    }
  }

  isArchiveDisabled()
  {
    switch (this.submissionData.status) {
      case 'resubmission': case 'validation_fail': case 'complete':
        return true;
        break;
      default:
        return false;
    }
  }
}
