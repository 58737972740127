import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})

export class UserService {

  constructor(
    private http: HttpClient,
  ) {}

  setUserRole(role) {
    if (role.some(userRole => userRole === 'SUPER_USER')) {
      localStorage.setItem('role', 'COORDINATOR')
    } else if (role.some(userRole => userRole === 'ADMINISTRATOR')) {
      localStorage.setItem('role', 'COORDINATOR')
    } else if (role.some(userRole => userRole === 'COORDINATOR')) {
      localStorage.setItem('role', 'COORDINATOR')
    } else {
      localStorage.setItem('role', 'ASSESSOR')
    }
  }

  getUserRole() {
    return localStorage.getItem('role') || ''
  }

  setUserName(name) {
    localStorage.setItem('name', name)
  }

  getUserName() {
    localStorage.getItem('name')
  }

  setUserId(id) {
    localStorage.setItem('id', id)
  }

  getUserId() {
    return localStorage.getItem('id')
  }

  getUserDetails() {
    return this.http.get('api/user')
  }

  getAssessors(role, credentialLevel) {
    return this.http.get([
      'api/users/assessor?role=',
      role,
      '&credential_level=',
      credentialLevel,
    ].join(''))
  }

}
