import { Component, OnInit, Input } from '@angular/core'
import { UserService } from './../../../services/user.service'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { base64 } from './base64'

var callAddFont = function () {
  this.addFileToVFS('Raleway-Medium-normal.ttf', base64.raleway)
  this.addFont('Raleway-Medium-normal.ttf', 'Raleway-Medium', 'normal')

  this.addFileToVFS('OpenSans-Regular-normal.ttf', base64.opensans)
  this.addFont('OpenSans-Regular-normal.ttf', 'OpenSans-Regular', 'normal')
}
jsPDF.API.events.push(['addFonts', callAddFont])

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})

export class InvoiceComponent implements OnInit {

  @Input() submissionsData
  assessments = []
  monthsWithInvoice = new Set()
  invoice = {}
  categories = [
    'completedAssessments',
    'resubmissions',
    'completedResubmissions',
    'clustersTwo',
    'clustersTwoResubmissions',
    'clustersTwoCompletedResubmissions',
    'clustersThree',
    'clustersThreeResubmissions',
    'clustersThreeCompletedResubmissions',
    'clustersFour',
    'clustersFourResubmissions',
    'clustersFourCompletedResubmissions',
  ]

  constructor(
    private userService: UserService,
  ) { }

  makeTableGroup(group, level) {
    let table = []

    this.invoice[group][level].forEach(assessment => {
      table.push([
        assessment.created_at.substring(0, 10),
        assessment.submission.candidate.name,
        assessment.submission.cluster?.name || assessment.submission.credentials[0].name,
        assessment.submission.credentials[0].level_name,
        assessment.outcome.charAt(0).toUpperCase() + assessment.outcome.slice(1).replaceAll('_', ' '),
      ])
    })

    return table
  }

  determineCredentialLevel(assessment, type) {
    let level = assessment.submission.credentials[0].level

    if (level >= 7) {
      this.invoice[type].above.push(assessment)
    } else {
      this.invoice[type].below.push(assessment)
    }
  }

  generateInvoice(date) {

    this.categories.forEach(category => {
      this.invoice[category] = {
        above: [],
        below: [],
      }
    })

    this.assessments.forEach(assessment => {
      if (assessment.created_at.substr(0, 7) === date) {

        if (assessment.cluster) {
          let numberOfCredentials = 'Four'
          if (assessment.submission.credentials.length === 2) {
            numberOfCredentials = 'Two'
          }
          if (assessment.submission.credentials.length === 3) {
            numberOfCredentials = 'Three'
          }
          if (
            assessment.outcome === 'testimony_fail' ||
            assessment.outcome === 'resubmission'
          ) {
            this.determineCredentialLevel(assessment, 'clusters' + numberOfCredentials + 'Resubmissions')
          } else if (assessment.submission.assessments.length === 1) {
            this.determineCredentialLevel(assessment, 'clusters' + numberOfCredentials)
          } else {
            this.determineCredentialLevel(assessment, 'clusters' + numberOfCredentials + 'CompletedResubmissions')
          }
        } else if (
          assessment.outcome === 'testimony_fail' ||
          assessment.outcome === 'resubmission'
        ) {
          this.determineCredentialLevel(assessment, 'resubmissions')
        } else if (assessment.submission.assessments.length === 1) {
          this.determineCredentialLevel(assessment, 'completedAssessments')
        } else {
          this.determineCredentialLevel(assessment, 'completedResubmissions')
        }
      }
    })

    var doc:any = new jsPDF()

    let tables = []

    this.categories.forEach(category => {
      tables.push({
        group: category,
        level: 'above',
      }, {
        group: category,
        level: 'below',
      })
    })

    doc.addImage(base64.icon, 'PNG', 14, 10, 10, 10)

    doc.setFont('Raleway-Medium')
    doc.setFontSize(14)

    let month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ][date.substr(5, 2) - 1]

    doc.text('Assessment Portal monthly invoice report - ' + month + ' ' + date.substr(0, 4), 28, 16.5)
    doc.setFontSize(10)

    tables.forEach(table => {

      let offset = doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 30

      if (this.invoice[table.group][table.level].length) {
        let title = []

        title.push([
          'Completed assessments',
          'Resubmissions',
          'Completed resubmissions',
          'Clusters with 2 credentials – Completed',
          'Clusters with 2 credentials – Resubmissions',
          'Clusters with 2 credentials – Completed resubmissions',
          'Clusters with 3 credentials – Completed',
          'Clusters with 3 credentials – Resubmissions',
          'Clusters with 3 credentials – Completed resubmissions',
          'Clusters with 4 credentials – Completed',
          'Clusters with 4 credentials – Resubmissions',
          'Clusters with 4 credentials – Completed resubmissions',
        ][this.categories.indexOf(table.group)])

        switch (table.level) {
          case 'above':
            title.push('Practitioner and above')
            break
          case 'below':
            title.push('Proficient and below')
            break
        }

        title.push(this.invoice[table.group][table.level].length)

        doc.text(title.join(' – '), 14, offset)
        autoTable(doc, {
          styles: {
            font: 'OpenSans-Regular',
            fontSize: 8,
          },
          headStyles: {
            fillColor: '#a84d8d',
          },
          head: [['Date', 'Candidate', 'Credentials', 'Level', 'Outcome']],
          body: this.makeTableGroup(table.group, table.level),
          startY: offset + 3
        })
      }
    })

    doc.save('Assessment Portal monthly invoice report - ' + date + '.pdf')

  }

  ngOnInit(): void {

    let userId = this.userService.getUserId()

    let clusters = []

    // remove assessments not created by user
    this.submissionsData.forEach(submission => {
      submission.assessments = submission.assessments.filter(assessment => {
        return assessment.owner.id === userId
      })
    })

    // remove submissions containing no assessments
    this.submissionsData = this.submissionsData.filter(submission => {
      return submission.assessments.length
    })

    // reduce cluster assessments
    this.submissionsData = this.submissionsData.filter(submission => {

      if (submission.credentials.length > 1) {

        let assessmentGroups = {}
        submission.assessments.forEach(assessment => {
          if (assessment.rubric?.id) {
            assessmentGroups[assessment.rubric.id] = assessmentGroups[assessment.rubric.id] || []
            assessmentGroups[assessment.rubric.id].push(assessment)
          }
        })

        let reducedAssessments = []
        for (const rubricId in assessmentGroups) {

          assessmentGroups[rubricId][0].cluster = submission.cluster || true

          let outcomes = [...new Set(assessmentGroups[rubricId].map(assessment => {
            return assessment.outcome
          }))]

          let outcome

          if (outcomes.includes('testimony_fail')) {
            outcome = 'testimony_fail'
          } else if (outcomes.includes('resubmission')) {
            outcome = 'resubmission'
          } else if (outcomes.includes('not_satisfied')) {
            outcome = 'not_satisfied'
          } else if (outcomes.includes('satisfied_lower_level')) {
            outcome = 'satisfied_lower_level'
          } else {
            outcome = 'satisfied'
          }

          assessmentGroups[rubricId][0].outcome = outcome

          reducedAssessments.push(assessmentGroups[rubricId][0])
        }

        submission.assessments = reducedAssessments
        clusters.push(submission)
      }

      return submission

    })

    // populate assessments array
    this.submissionsData.forEach(submission => {
      submission.assessments.forEach(assessment => {
        assessment.submission = submission
        this.assessments.push(assessment)
      })
    })

    // sort assessments by creation date
    this.assessments = this.assessments.sort((a, b) => {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    })

    // create unique list of months
    this.assessments.forEach(assessment => {
      this.monthsWithInvoice.add(assessment.created_at.substr(0, 7))
    })

  }

}
