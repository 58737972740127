<div class="row">
  <div class="col-8">
    <p
      *ngIf="submissionData.outcome"
      class="h3 mb-3 outcome"
      [ngClass]="{
        'ap-bg-blue': submissionData.outcome === 'satisfied',
        'ap-bg-red': submissionData.outcome === 'not_satisfied'
      }"
    >
      {{ submissionData.outcome.replaceAll('_', ' ') | uppercase }}
    </p>
    <p class="h5 ap-text-green">
      {{ submissionData.candidate.name }}
    </p>

    <div class="mb-3">
      <ng-container *ngIf="submissionData.cluster">
        <p class="h4">
          {{ utilitiesService.removeLevelFromCredential(
          submissionData.cluster.name,
          submissionData.credentials[0].level_name
        )
          }}
          –
          {{ submissionData.credentials[0].level_name }}
        </p>
        <p
          *ngFor="let credential of submissionData.credentials"
          class="h5"
        >
          {{ utilitiesService.removeLevelFromCredential(
          credential.name,
          credential.level_name
        )
          }}
        </p>
      </ng-container>

      <ng-container *ngIf="!submissionData.cluster">
        <p class="h4">
          {{ submissionData.credentials[0].name }}
        </p>
        <p class="h5">
          {{ submissionData.credentials[0].level_name }}
        </p>
      </ng-container>
    </div>

    <p>
      <span
        *ngIf="submissionData.step"
      >
        Step: {{ submissionData.step }}
      </span>
      <br>
      <span
        *ngIf="submissionData.status"
      >
        Status: {{ submissionData.status }}
      </span>
    </p>

    <p
      *ngIf="submissionData.candidate.tags.length"
    >
      Tags: {{ submissionData.candidate.tags.join(', ') }}
    </p>

    <p class="mb-5">
      <span
        *ngIf="submissionData.outcome_provided_at"
      >
        Outcome provided: {{ submissionData.outcome_provided_at | date: "d/M/yyyy" }}
        <br>
      </span>
      <ng-container
        *ngIf="submissionData.assessors.length"
      >
        Assessment due: {{ submissionData.assessors.slice(-1).pop().due_date | date: "d/M/yyyy" }}
        <br>
      </ng-container>
      Date submitted: {{ submissionData.created_at | date: "d/M/yyyy" }}
    </p>
  </div>
  <div class="col-4">
    <button
      (click)="sendOutcome()"
      *ngIf="submissionData.pending_action === 'send_outcome'"
      class="blue w-100 mb-3"
    >
      Send outcome
    </button>
    <button
      (click)="openRubricMain()"
      class="blue secondary w-100 mb-3"
    >
      Open rubric
    </button>
    <button
      (click)="open(content)"
      *ngIf="submissionData.pending_action === 'provide_outcome'"
      class="yellow secondary w-100 mb-3"
    >
      Report file error
    </button>
    <button
      (click)="archiveEverything()"
      *ngIf="userService.getUserRole() !== 'ASSESSOR'"
      class="red secondary w-100"
    >
      Archive all assessments
    </button>
  </div>
</div>


<ng-template
  #content
  let-c="close"
  let-d="dismiss"
>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title"
    >
      Report file error
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form
    [formGroup]="testimonyFailForm"
    (ngSubmit)="onSubmit()"
  >

    <div class="modal-body">
      <div class="form-group">
        <label>{{ fileErrorInstructions }}</label>
        <div class="custom-control custom-radio">
          <input
            type="radio"
            class="custom-control-input"
            id="testimonyFail_videoTestimonyIssue"
            formControlName="submissionIssue"
            value="vt"
            [ngClass]="
              {'is-invalid':
                testimonyFailForm.get('submissionIssue').invalid
              }"
          >
          <label
            class="custom-control-label"
            for="testimonyFail_videoTestimonyIssue"
          >
            Video file
          </label>
        </div>
        <div class="custom-control custom-radio mb-3">
          <input
            type="radio"
            class="custom-control-input"
            id="testimonyFail_writtenExampleIssue"
            formControlName="submissionIssue"
            value="we"
            [ngClass]="
              {'is-invalid':
                testimonyFailForm.get('submissionIssue').invalid
              }"
          >
          <label
            class="custom-control-label"
            for="testimonyFail_writtenExampleIssue"
          >
            Evidence file
          </label>
          <div class="invalid-feedback">
            Please select a file error report reason.
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="notes">
          Error description
        </label>
        <textarea
          class="form-control"
          id="notes"
          rows="10"
          formControlName="notes"
          (focus)="testimonyFailForm.get('notes').markAsUntouched()"
          [ngClass]="
            {'is-invalid':
              testimonyFailForm.get('notes').invalid && testimonyFailForm.get('notes').touched
            }"
        ></textarea>
        <p class="invalid-feedback">
          Please provide File error report feedback.
        </p>
      </div>

      <div class="form-group">
        <label for="signature">
          Please enter your name below to confirm your file error report
        </label>
        <input
          class="form-control"
          type="text"
          name="signature"
          formControlName="signature"
          placeholder="Assessor name"
          (focus)="testimonyFailForm.get('signature').markAsUntouched()"
          [ngClass]="
            {'is-invalid':
              testimonyFailForm.get('signature').invalid && testimonyFailForm.get('signature').touched
            }"
        />
        <p class="invalid-feedback">
          Please provide your signature.
        </p>
      </div>

    </div>
    <div class="modal-footer">
      <button
        ngbAutofocus
        class="red secondary"
        (click)="d()"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="green"
      >
        Submit
      </button>
    </div>
  </form>
</ng-template>
