import { Injectable }             from '@angular/core'
import { HttpClient }             from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, retry }      from 'rxjs/operators'
import { SubmissionService }      from './submission.service'

@Injectable({
  providedIn: 'root',
})

export class FileService {

  downloadEvidenceFile(evidenceId, submissionVersion) {
    return this.http.get([
      'api',
      'files',
      'submission',
      this.submissionService.getUuid(),
      'evidence',
      evidenceId,
      'download',
      submissionVersion ? submissionVersion : 1,
    ].join('/'))
  }

  downloadCredentialLevelRubric(credentialLevelVid) {
    return this.http.get([
      'api',
      'files',
      'credential_level',
      credentialLevelVid,
      'rubric_template',
      'download',
    ].join('/'))
  }

  downloadClusterRubric(clusterVid) {
    return this.http.get([
      'api',
      'files',
      'cluster',
      clusterVid,
      'rubric_template',
      'download',
    ].join('/'))
  }

  downloadAssessmentRubric(assessmentUuid) {
    return this.http.get([
      'api',
      'files',
      'assessment',
      assessmentUuid,
      'rubric',
      'download',
    ].join('/'))
  }

  uploadRubric(formData) {
    return this.http.post([
      'api',
      'files',
      'rubric',
      'upload',
    ].join('/'), formData)
  }

  downloadNotesFile(uuid) {
    return this.http.get([
      'api',
      'files',
      'note',
      uuid,
      'download',
    ].join('/'))
  }

  downloadOrphanedFile(uuid) {
    return this.http.get([
      'api',
      'files',
      uuid,
      'download',
    ].join('/'))
  }

  constructor(
    private http: HttpClient,
    private submissionService: SubmissionService,
  ) {}

}
