import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import {
  SubmissionService,
} from './submission.service'

@Injectable({
  providedIn: 'root',
})

export class VtService {

  constructor(
    private http: HttpClient,
    private submissionService: SubmissionService,
  ) {}

  trackSessions() {
    return this.http.get(
      [
        'api',
        'vt',
        'track_sessions',
        this.submissionService.getUuid(),
      ].join('/')
    )
  }

  getStreamUrl(questionUuid) {
    return this.http.get(
      [
        'api',
        'files',
        'vt_question',
        questionUuid,
        'download',
      ].join('/')
    )
  }

  createSession(questions) {
    return this.http.post('api/vt/create_session', {
      uuid: this.submissionService.getUuid(),
      questions: questions,
    })
  }

  getQuestions(nid) {
    return this.http.get(
      [
        'integration',
        'cms',
        'credential_levels',
        nid,
        'questions',
      ].join('/')
    )
  }

  getClusterQuestions(nid) {
    return this.http.get(
      [
        'integration',
        'cms',
        'clusters',
        nid,
        'questions',
      ].join('/')
    )
  }

}
