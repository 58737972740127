import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'

import {
  UserService
} from './../../services/user.service'

import {
  SubmissionService,
} from './../../services/submission.service'

import {
  ToastService,
} from './../../services/toast.service'

@Component({
  selector: 'app-manage-submission',
  templateUrl: './manage-submission.component.html',
  styleUrls: ['./manage-submission.component.scss'],
})

export class ManageSubmissionComponent implements OnInit {

  @Input() submissionData
  @Input() credentialLevel
  @Input() outcome

  academicAssessor
  academicAssessors
  academicAssessments

  industryAssessor
  industryAssessors
  industryAssessments

  constructor(
    private userService: UserService,
    private submissionService: SubmissionService,
    private toastService: ToastService,
  ) {}

  reflectAssessorAssignment = (event, role) => {
    this[role + 'Assessor'] = this[role + 'Assessors'].find(assessor => assessor.id === event)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.makeVars()
  }

  filterAssessments(category) {
    let filteredAssessments = []

    this.submissionData.assessments.slice().reverse().forEach(assessment => {
      if (assessment.owner.id === this[category + 'Assessor']?.id && assessment.status !== 'archived') {
        if (!filteredAssessments.some(filteredAssessment => {
          return assessment.credential_level === filteredAssessment.credential_level
        })) {
          filteredAssessments.push({
            outcome: assessment.outcome,
            id: assessment.id,
            created_at: assessment.created_at,
            credential_level: assessment.credential_level,
            credential: this.submissionData.credentials.find(credential => {
              return assessment.credential_level == credential.id
            })?.name,
          })
        }
      }
    })

    return filteredAssessments
  }

  makeVars() {
    this.submissionData.assessors.forEach(assessor => {
      if (assessor.role === 'academic') {
        this.academicAssessor = assessor
      }
      if (assessor.role === 'industry') {
        this.industryAssessor = assessor
      }
    })

    if (!this.academicAssessor) {
      this.userService.getAssessors(
        'academic',
        this.submissionData.credentials[0].id,
      ).subscribe(data => {
        this.academicAssessors = data
      })
    }

    if (!this.industryAssessor) {
      this.userService.getAssessors(
        'industry',
        this.submissionData.credentials[0].id,
      ).subscribe(data => {
        this.industryAssessors = data
      })
    }

    this.academicAssessments = this.filterAssessments('academic')
    this.industryAssessments = this.filterAssessments('industry')

  }

  ngOnInit(): void {

    this.makeVars()

  }

}
