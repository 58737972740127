<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [header]="toast.header"
  [autohide]="!!toast.delay"
  [delay]="toast.delay"
  (hide)="toastService.remove(toast)"
  [class]="toast.colour"
>
  {{ toast.body }}
</ngb-toast>
