<div class="row">
  <div
    class="col"
    *ngFor="let submission of actionRequiredSubmissions | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
  >
    <div
      [routerLink]="['/submission', submission.id]"
      class=" d-flex flex-column h-100"
      [ngClass]="daysLeftClass(submission)"
    >
      <div class="card flex-fill d-flex flex-column">
        <p class="candidate-name h6">
          {{ submission.candidate.name }}
        </p>
        <p
          class="credentials h6"
          *ngIf="!submission.cluster"
        >
          {{ submission.credentials[0].name }}
        </p>
        <p
          class="credentials h6"
          *ngIf="submission.cluster"
        >
          {{ submission.cluster.name }}
        </p>
        <p class="level-name h6">
          {{ submission.credentials[0].level_name }}
        </p>
      </div>
      <p class="pending-action">
        {{ submission.pending_action.replaceAll('_', ' ') }}
      </p>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12" *ngIf="actionRequiredSubmissions.length > pageSize">
    <ngb-pagination
      class="d-flex justify-content-center"
      [collectionSize]="actionRequiredSubmissions.length"
      [(pageSize)]="pageSize"
      [(page)]="page"
      [maxSize]="5"
      [rotate]="true"
    ></ngb-pagination>
  </div>
</div>
