import { Component, OnInit }                  from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router }                             from '@angular/router'
import { AuthService }                        from './../services/auth.service'
import { EventService }                       from './../services/event.service'

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})

export class PasswordResetComponent implements OnInit {

  token = null
  passwordsDoNotMatch = false

  requestTokenForm = new FormGroup({
    email: new FormControl('', {
      validators: [
        Validators.email,
        Validators.required,
      ],
      updateOn: "submit",
    }),
  })

  resetPasswordForm = new FormGroup({
    email: new FormControl('', {
      validators: [
        Validators.email,
        Validators.required,
      ],
      updateOn: "submit",
    }),
    password: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
    confirmPassword: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
  })

  onSubmitRequestTokenForm() {
    this.requestTokenForm.controls.email.markAsTouched()

    if (this.requestTokenForm.status === "VALID") {
      this.authService.requestPasswordResetLink(
      	this.requestTokenForm.controls.email.value
      ).subscribe((response: any) => {
        this.eventService.setLoginMessage(response.message, 'ap-text-green')
        this.router.navigate(["login"])
      })
    }
  }

  resetPasswordsMatchValidation() {
    if (this.passwordsDoNotMatch) {
      this.resetPasswordForm.controls.password.setErrors(null)
      this.resetPasswordForm.controls.confirmPassword.setErrors(null)
      this.passwordsDoNotMatch = false
    }
  }

  onSubmitResetPasswordForm() {
    this.resetPasswordForm.controls.email.markAsTouched()
    this.resetPasswordForm.controls.password.markAsTouched()
    this.resetPasswordForm.controls.confirmPassword.markAsTouched()
    this.passwordsDoNotMatch = false

    if (this.resetPasswordForm.status === "VALID") {
      if (this.resetPasswordForm.controls.password.value ===
          this.resetPasswordForm.controls.confirmPassword.value) {
        this.authService.resetPassword(
          this.token,
          this.resetPasswordForm.controls.email.value,
          this.resetPasswordForm.controls.password.value,
          this.resetPasswordForm.controls.confirmPassword.value,
        ).subscribe((response: any) => {
          this.eventService.setLoginMessage(response.message, 'ap-text-green')
          this.router.navigate(["login"])
        })
      } else {
        this.resetPasswordForm.controls.password.setErrors({incorrect: true})
        this.resetPasswordForm.controls.confirmPassword.setErrors({incorrect: true})
        this.passwordsDoNotMatch = true
      }
    }
  }

  ngOnInit(): void {
    let endString = this.router.url.split('/').pop()
    if (endString !== 'password-reset') {
      this.token = endString
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private eventService: EventService,
  ) {}

}
