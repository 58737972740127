<div class="row">
  <div class="col-md-8 col-lg-6">
    <form
      [formGroup]="assessmentForm"
      (ngSubmit)="onSubmit(content)"
    >
      
      <div
        *ngFor="let credential of credentials; let i = index"
        class="form-group"
      >
        <label for="assessmentOutcome{{i}}">
          Outcome
          <ng-container
            *ngIf="credentials.length > 1"
          >
            – {{ credential.name }}
          </ng-container>
        </label>
        <select
          (change)="saveOutcome()"
          class="custom-select custom-select-lg"
          id="assessmentOutcome{{i}}"
          formControlName="assessmentOutcome{{i}}"
        >
          <option
            *ngFor="let outcome of credential.outcomes"
            [value]="outcome"
          >
            {{ utilitiesService.formatOutcome(outcome) }}
          </option>
        </select>
      </div>
      
      <div
        class="form-group"
        *ngIf="showResubmissionReason"
      >
        <label>Resubmission reason</label>
        <div class="custom-control custom-radio d-none">
          <input
            type="radio"
            class="custom-control-input"
            id="resubmission_videoTestimonyIssue"
            formControlName="submissionIssue"
            value="vt"
            [ngClass]="
              {'is-invalid':
                assessmentForm.get('submissionIssue').invalid
              }"
          >
          <label
            class="custom-control-label"
            for="resubmission_videoTestimonyIssue"
          >
            Video testimony issue
          </label>
        </div>
        <div class="custom-control custom-radio mb-3">
          <input
            type="radio"
            class="custom-control-input"
            id="resubmission_writtenExampleIssue"
            formControlName="submissionIssue"
            value="we"
            [ngClass]="
              {'is-invalid':
                assessmentForm.get('submissionIssue').invalid
              }"
          >
          <label
            class="custom-control-label"
            for="resubmission_writtenExampleIssue"
          >
            Written example/evidence issue
          </label>
          <div class="invalid-feedback">
            Please select a resubmission reason.
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="notes">
          Feedback
        </label>
        <textarea
          (input)="saveFeedback($event.target.value)"
          class="form-control"
          id="notes"
          rows="10"
          formControlName="notes"
          (focus)="assessmentForm.get('notes').markAsUntouched()"
          [ngClass]="
            {'is-invalid':
              assessmentForm.get('notes').invalid && assessmentForm.get('notes').touched
            }"
        ></textarea>
        <p class="d-flex justify-content-between mt-1 ap-text-grey">
          <small>
            Feedback draft will remain if browser is closed or back button is used.
          </small>
          <small>
            {{ saving ? 'Saving...' : 'Saved.' }}
          </small>
        </p>
        <p class="invalid-feedback">
          Please provide assessment feedback.
        </p>
      </div>
      <div class="form-group">
        <label>
          Rubric file
        </label>
        <div class="custom-file">
          <input
            name="rubricFile"
            id="rubricFile"
            type="file"
            class="custom-file-input"
            formControlName="rubricFile"
            (change)="handleFileChange($event)"
            (focus)="assessmentForm.get('rubricFile').markAsUntouched()"
            [ngClass]="
              {'is-invalid':
                assessmentForm.get('rubricFile').invalid && assessmentForm.get('rubricFile').touched
              }"
          />
          <label
            for="rubricFile"
            class="custom-file-label"
          >
            {{ file.name }}
          </label>
          <p class="invalid-feedback">
            Please upload a rubric file.
          </p>
        </div>
      </div>
      <div class="form-group">
        <label for="signature">
          Please sign your name below to validate your assessment outcome
        </label>
        <input
          class="form-control"
          type="text"
          name="signature"
          formControlName="signature"
          placeholder="Assessor name"
          (focus)="assessmentForm.get('signature').markAsUntouched()"
          [ngClass]="
            {'is-invalid':
              assessmentForm.get('signature').invalid && assessmentForm.get('signature').touched
            }"
        />

        <p class="invalid-feedback">
          Please provide your signature.
        </p>
      </div>

      <button
        class="green mt-3"
        type="submit"
      >
        Submit assessment
      </button>
    </form>
  </div>
</div>

<ng-template
  #content
  let-c="close"
  let-d="dismiss"
>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Outcome confirmation</h4>
    <button
      class="close"
      aria-label="Close"
      (click)="d()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="mb-3">
      { credentials.length,
        plural,
        =1 {Below is the outcome you have selected for the credential.}
        other {Below are the outcomes you have selected for each credential.}
      }
    </p>
    <ul>
      <li *ngFor="let credential of credentials; let i = index">
        {{ credential.name }} –
        <span
          class="text-capitalize"
          [ngClass]="{
            'ap-text-blue': assessmentForm.get('assessmentOutcome' + i).value === 'satisfied',
            'ap-text-red': assessmentForm.get('assessmentOutcome' + i).value === 'not_satisfied',
            'ap-text-yellow': assessmentForm.get('assessmentOutcome' + i).value === 'resubmission' || assessmentForm.get('assessmentOutcome' + i).value === 'satisfied_lower_level'
          }"
        >{{ assessmentForm.get('assessmentOutcome' + i).value.replaceAll('_', ' ') }}</span>

      </li>
    </ul>
    <p>
      { credentials.length,
        plural,
        =1 {Are you sure this is correct?}
        other {Are you sure these are correct?}
      }
    </p>
  </div>
  <div class="modal-footer">
    <button
      class="red secondary"
      (click)="d()"
    >
      No
    </button>
    <button
      ngbAutofocus
      class="green"
      (click)="c()"
    >
      Yes
    </button>
  </div>
</ng-template>
