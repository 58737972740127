import { Component }   from '@angular/core'
import { Router }      from '@angular/router'
import { AuthService } from './../services/auth.service'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})

export class NavComponent {

  constructor(
    private authService: AuthService,
    public router: Router,
  ) {}

  logout() {
    this.authService.logout().subscribe(
      data => {
        this.authService.removeToken()
      }
    )
  }

}
