<div class="row">
  <div class="col-md-2">
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="active"
      class="nav-pills"
      orientation="vertical"
    >
      <ng-container
        *ngFor="let writtenExample of writtenExamplesData"
      >
        <li class="version" *ngIf="writtenExamplesData.length > 1">
          <p>
            Version {{ writtenExample.version }}
            <br>
            <span>{{ writtenExample.created_at | date: "d/M/yyyy" }}</span>
          </p>
        </li>
        <li
          *ngFor="let example of writtenExample.evidence; let i = index"
          ngbNavItem
        >
          <a
            ngbNavLink
            class="button grey secondary text-center mb-3"
          >
            Example {{ i + 1 }}
          </a>
          <ng-template ngbNavContent>

            <div class="credential ap-text-green">
              <div class="row">
                <div class="col-12">
                  <div
                    class="criteria-group mb-5"
                    *ngFor="let criterias of example.formattedCriteria | keyvalue"
                  >
                    <p>
                      {{ criterias.value.name }}
                    </p>
                    <ul>
                      <li
                        *ngFor="let criteria of criterias.value.criteria"
                      >
                        {{ criteria }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div
                  *ngFor="let question of example.questions"
                  class="mb-5"
                >
                  <p class="h5">
                    {{ question.question_title }}
                  </p>
                  <p class="my-3 text-muted h6">
                    {{ question.question_instruction }}
                  </p>
                  <p>
                    {{ question.answer }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  *ngFor="let evidence of example.files"
                  class="mb-5"
                >    
                  <p>
                    {{ evidence.name }}
                  </p>
                  <p
                    *ngIf="evidence.role"
                  >
                    {{ evidence.role.charAt(0).toUpperCase() + evidence.role.slice(1) }}
                  </p>
                  <p class="ap-text-grey">
                    {{ evidence.file_attached_date.slice(0, 10) | date: "dd/MM/yyyy" }}
                  </p>
                  <p>
                    {{ evidence.description }}
                  </p>
                  <button
                    class="grey secondary"
                    (click)="this.downloadEvidenceFile(evidence.id, evidence.submission_version)"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>

          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="col-md-10">
    <div class="row">
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>

