import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core'
import { NotesService } from '../../services/notes.service'
import { UserService } from '../../services/user.service'
import { FileService } from '../../services/file.service'

import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms'

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})

export class NotesComponent implements OnInit {

  constructor(
    private notesService: NotesService,
    private userService: UserService,
    private fileService: FileService,
  ) { }

  notesForm = new FormGroup({
    notes: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
    supportingFile: new FormControl(),
    visibleToAssessors: new FormControl(),
  })

  userRole
  @Input() notesData
  @Output() submittedNotes = new EventEmitter

  file: any = {}

  postNote(file) {

    let noteData: any = {
      notes: this.notesForm.controls.notes.value,
      visible_to_assessors: this.notesForm.controls.visibleToAssessors.value,
    }

    if (file) {
      noteData.file = file
    }

    this.notesService.createNote(noteData).subscribe(() => {
      this.submittedNotes.emit()
      this.notesForm.reset()
      this.notesForm.controls.visibleToAssessors.setValue(false)
      this.file = {}
    })
  }

  onSubmit() {

    this.notesForm.controls.notes.markAsTouched()

    if (this.notesForm.status === "VALID") {
      if (this.file.blob) {
        let formData = new FormData()
        formData.append("file", this.file.blob, this.file.name)
        this.fileService.uploadRubric(formData).subscribe((rubricData: any) => {
          this.postNote(rubricData.data.id)
        })
      } else {
        this.postNote(null)
      }
    }
  }

  downloadFile(noteId) {
    this.fileService.downloadNotesFile(noteId).subscribe((data: any) => {
      window.open(data.url)
    })
  }

  ngOnInit(): void {
    this.notesForm.controls.visibleToAssessors.setValue(false)

    this.userRole = this.userService.getUserRole()
  }

  handleFileChange(event) {
    let newFile = event.target.files[0]
    let blob = new Blob([newFile], {
      type: newFile.type,
    })
    this.file = {
      blob: blob,
      name: newFile.name,
    }
  }

}
