<div class="row">
  <div class="col-md-2">
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="active"
      class="nav-pills"
      orientation="vertical"
    >
      <li
        *ngFor="let assessments of groupedAssessments"
        ngbNavItem
      >
        <a
          ngbNavLink
          class="button grey secondary text-center mb-3"
        >
          {{ assessments[0].is_archived ? 'Archived: ' : '' }}{{ getRole(assessments[0]) | titlecase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-md-8">
              <p class="h5 ap-text-green">
                {{ assessments[0].owner.name }}
              </p>

              <p
                *ngFor="let assessment of assessments"
                class="h4"
                [ngClass]="assessment.is_archived ? 'ap-text-grey' : utilitiesService.getOutcomeClass(assessment.outcome)"
              >
                {{ assessment.is_archived ? 'Archived:' : '' }}
                {{ assessment.outcome.replaceAll('_', ' ') | uppercase }}
                {{ assessment.outcome === 'testimony_fail' ? '– ' + assessment.testimony_fail_reason : '' }}
                <span
                  class="ap-text-grey"
                  *ngIf="assessments.length > 1"
                >
                  – {{ getCredentialName(assessment.credential_level) }}
                </span>
              </p>

              <p>
                Date submitted: {{ assessments[0].created_at | date: "d/M/yyyy" }}
              </p>

              <p>
                {{ assessments[0].notes }}
              </p>

              <p
                class="ap-text-grey"
                *ngIf="assessments[0].archived_reason"
              >
                Archived reason: {{ assessments[0].archived_reason }}
              </p>

              <button
                *ngIf="assessments[0].outcome !== 'testimony_fail'"
                class="button blue secondary"
                (click)="openRubricFile(assessments[0].id)"
              >
                Open completed rubric
              </button>
            </div>
          </div>

        </ng-template>
      </li>
    </ul>
  </div>
  <div class="col-md-10">
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
