import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, timer } from 'rxjs'

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  getTokenExpiry(token) {
    if (token) {
      const jwt = JSON.parse(atob(token.split('.')[1]))
      const now: any = new Date().getTime()
      return jwt.exp - (now - now%1000) / 1000
    }
    return null
  }

  login(email, password): Observable<any> {
    return this.http.post('api/auth/login', {
      email: email,
      password: password,
    })
  }

  logout() {
    return this.http.post('api/auth/logout', {})
  }

  renewToken() {
    this.http.post('api/auth/refresh', {}).subscribe((data: any) => {
      this.setToken(data.access_token)
    })
  }

  setToken(token) {
    localStorage.setItem('token', token)
  }

  getToken() {
    return localStorage.getItem('token')
  }

  removeToken() {
    localStorage.removeItem('id')
    localStorage.removeItem('name')
    localStorage.removeItem('role')
    localStorage.removeItem('token')
  }

  renewTokenTimer() {
    const source = timer(0, 180000)
    const subscribe = source.subscribe(() => {
      if (this.getToken()) {
        this.renewToken()
      }
    })
  }

  requestPasswordResetLink(email) {
    return this.http.post('api/auth/password/request', {
      email: email,
    })
  }

  validateResetLink(token) {
    return this.http.get('api/auth/password/validate/' + token)
  }

  resetPassword(token, email, password, confirmPassword) {
    return this.http.post('api/auth/password/reset/' + token, {
      email: email,
      password: password,
      password_confirmation: confirmPassword,
    })
  }

  constructor(
    private http: HttpClient,
  ) {
    this.renewTokenTimer()
  }
}
