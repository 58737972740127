<nav>
  <div class="container">
    <div class="row">
      <div class="col d-flex align-items-center">
        <div class="flex-fill">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
            <polygon points="16.5 0.2 20.2 6.6 20.2 0.2"></polygon>
            <path d="M0 0.2L0 2.9 12.2 23.9C13.4 23.7 14.6 23.2 15.6 22.5L2.5 0.2 0 0.2Z"></path>
            <path d="M11.6 0.2L7.3 0.2 18.6 19.5C19.5 18.2 20 16.6 20.2 14.9L11.6 0.2Z"></path>
            <path d="M0 14C0 18.6 3 22.4 7.2 23.7L0 11.5 0 14Z"></path>
            <path d="M24.2 21.9C24.2 23 23.3 23.9 22.2 23.9 21.1 23.9 20.2 23 20.2 21.9 20.2 20.9 21.1 20 22.2 20 23.3 20 24.2 20.9 24.2 21.9"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31">
            <path d="M29.7 15.5C29.7 23.7 23.1 30.3 15 30.3 6.8 30.3 0.2 23.7 0.2 15.5 0.2 7.3 6.8 0.7 15 0.7 23.1 0.7 29.7 7.3 29.7 15.5" fill="#FEFEFE"></path>
            <path d="M19.9 19.9L14.2 10.2 15 8.9 21.3 19.9 19.9 19.9ZM17.2 19.9L12.9 12.5 13.6 11.2 18.6 19.9 17.2 19.9ZM14.5 19.9L11.5 14.8 12.3 13.6 15.9 19.9 14.5 19.9ZM11.8 19.9L10.2 17.2 10.9 15.9 13.2 19.9 11.8 19.9ZM8.6 19.9L9.6 18.3 10.5 19.9 8.6 19.9ZM7.3 7.7L7.3 18.2C7.3 22.4 10.7 25.8 15 25.8 19.2 25.8 22.6 22.4 22.6 18.2L22.6 7.7 7.3 7.7Z" fill="#1D1D1B"></path>
          </svg>
          <a
            class="flex-fill"
            routerLink="/submissions"
            *ngIf="!router.isActive('login', false) && !router.isActive('password-reset', false)"
          >Dashboard</a>
        </div>
        <a
          routerLink="/login"
          (click)="logout()"
          *ngIf="!router.isActive('login', false) && !router.isActive('password-reset', false)"
        >Logout</a>
      </div>
    </div>
  </div>
</nav>
