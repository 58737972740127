import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'

import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms'

import {
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap'

import {
  CommonModule,
} from '@angular/common'

@Component({
  selector: 'app-accept-assignments-modal',
  templateUrl: './accept-assignments-modal.component.html',
  styleUrls: ['./accept-assignments-modal.component.scss'],
})

export class AcceptAssignmentsModalComponent {

  @ViewChild('content') content
  @Input() newSubmissions
  @Output() acceptedSubmissions = new EventEmitter

  acceptAssignmentsForm = new FormGroup({
    numberOfAssignmentsSelect: new FormControl(''),
  })

  constructor(
    private modalService: NgbModal,
  ) {}

  ngAfterViewInit() {
    this.modalService.open(
      this.content,
      { backdrop: 'static' },
    ).result.then(() => {
      this.acceptedSubmissions.emit(
        this.acceptAssignmentsForm.get(
          'numberOfAssignmentsSelect',
        ).value
      )
    }, () => {})

    this.acceptAssignmentsForm.get(
      'numberOfAssignmentsSelect'
    ).setValue(
      this.newSubmissions.length
    )
  }

}
