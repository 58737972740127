import { Component, Output, EventEmitter } from '@angular/core'
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})

export class DatePickerComponent {

@Output() dateRange = new EventEmitter

hoveredDate: NgbDate | null = null

  fromDate: NgbDate | null
  toDate: NgbDate | null

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {}

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date
    } else {
      this.toDate = null
      this.fromDate = date
    }
    this.dateRange.emit([this.fromDate, this.toDate])
  }

  isHovered(date: NgbDate) {
    return this.fromDate &&
           !this.toDate &&
           this.hoveredDate &&
           date.after(this.fromDate) &&
           date.before(this.hoveredDate)
  }

  isInside(date: NgbDate) {
    return this.toDate &&
           date.after(this.fromDate) &&
           date.before(this.toDate)
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) ||
           (this.toDate && date.equals(this.toDate)) ||
           this.isInside(date) ||
           this.isHovered(date)
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input)
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue
  }

  datepickerClear() {
    this.fromDate = null
    this.toDate = null
    this.dateRange.emit(null)
  }

}
