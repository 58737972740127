<p
  class="mb-3 h4"
>
  Assessors
</p>

<div class="row">
  <div class="col mb-5">

    <p class="h5">
      Academic assessor
    </p>

    <app-assessor-details
      *ngIf="academicAssessor"
      [assessor]="academicAssessor"
      [assessments]="academicAssessments"
      [submissionData]="submissionData"
    >
    </app-assessor-details>

    <app-assign-assessor
      *ngIf="!academicAssessor"
      [assessors]="academicAssessors"
      [oppositeAssessor]="industryAssessor"
      role="academic"
      (assignedAssessor)="reflectAssessorAssignment($event, 'academic')"
    >
    </app-assign-assessor>

  </div>
</div>

<div class="row">
  <div class="col mb-5">

    <p class="h5">
      Industry assessor
    </p>

    <app-assessor-details
      *ngIf="industryAssessor"
      [assessor]="industryAssessor"
      [assessments]="industryAssessments"
      [submissionData]="submissionData"
    >
    </app-assessor-details>

    <app-assign-assessor
      *ngIf="!industryAssessor"
      [assessors]="industryAssessors"
      [oppositeAssessor]="academicAssessor"
      role="industry"
      (assignedAssessor)="reflectAssessorAssignment($event, 'industry')"
    >
    </app-assign-assessor>

  </div>
</div>
