import { Component, OnInit } from '@angular/core'
import { VersionService }    from './../services/version.service'
import { environment }       from './../../environments/environment'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnInit {

  apiVersion
  frontEndVersion

  constructor(
    private versionService: VersionService,
  ) {}

  ngOnInit(): void {

    this.frontEndVersion = environment.version

    this.versionService.getVersion().subscribe(
      (data: any) => {
        this.apiVersion = data.version
      }
    )

  }

}
