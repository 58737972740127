import { Injectable }             from '@angular/core'
import { HttpClient }             from '@angular/common/http'
import { Router }                 from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError, retry }      from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})

export class SubmissionService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  getSubmissions() {
    return this.http.get('api/submissions')
  }

  getSubmission() {
    return this.http.get([
      'api',
      'submissions',
      this.getUuid(),
    ].join('/'))
  }

  getOverview() {
    return this.http.get([
      'api',
      'submissions',
      this.getUuid(),
      'overview',
    ].join('/'))
  }

  listAssessments() {
    return this.http.get([
      'api',
      'submissions',
      this.getUuid(),
      'assessments',
    ].join('/'))
  }

  assignAssessor(
    role,
    id,
  ) {
    return this.http.post([
      'api',
      'submissions',
      this.getUuid(),
      'assessor',
    ].join('/'), {
      role: role,
      id: id,
    })
  }

  respondToAssignment(
    assessmentUuid,
    status,
  ) {
    return this.http.put([
      'api',
      'submissions',
      assessmentUuid,
      'assessors',
    ].join('/'), {
      status: status
    })
  }

  updateAssessmentStatus(
    assessmentUuid,
    status,
    status_reason,
    signature,
  ) {
    return this.http.put([
      'api',
      'submissions',
      this.getUuid(),
      'assessments',
      assessmentUuid,
    ].join('/'), {
      status: status,
      status_reason: status_reason,
      signature: signature,
    })
  }

  getUuid() {
    return this.router.url.split('/').pop()
  }

  createAssessment(
    outcome,
    context = null,
    notes,
    rubricUuid = null,
    signature,
    vid,
  ) {

    let body: any = {
      outcome: outcome,
      notes: notes,
      signature: signature,
      credential_id: vid,
    }

    if (rubricUuid) {
      body.rubric = rubricUuid
    }

    if (context) {
      body.context = context
    }

    return this.http.post([
      'api',
      'submissions',
      this.getUuid(),
      'assessments',
    ].join('/'), body)
  }

  sendOutcome() {
    return this.http.post([
      'api',
      'submissions',
      this.getUuid(),
      'outcome',
    ].join('/'), {})
  }

}
