<footer>
  <div class="container py-3 d-md-flex justify-content-between">
    <div>
      Front-end version: {{ frontEndVersion }}
    </div>
    <div>
      API version: {{ apiVersion }}
    </div>
  </div>
</footer>
