import { Injectable }             from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
}                                 from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError }             from 'rxjs/operators'
import { Router }                 from '@angular/router'
import { environment }            from './../environments/environment'
import { ToastService }           from './services/toast.service'
import { AuthService }            from './services/auth.service'
import { EventService }           from './services/event.service'

@Injectable()

export class ApInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastService: ToastService,
    private authService: AuthService,
    private eventService: EventService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    request = request.clone({
      url: environment.apiUrl + request.url,
      setHeaders: {
        Authorization: 'Bearer ' + this.authService.getToken(),
      },
    })

    return next.handle(request)
    .pipe(
      catchError(error => {
        if (error.url.endsWith('/api/auth/login')) {
          this.eventService.setLoginMessage(
            error.error.message,
            'ap-text-red',
          )
        } else {
          if (error.url.endsWith('/api/auth/refresh')) {
            this.router.navigate(['/'])
          }
          if (error.status === 500) {
            this.toastService.show({
              header: '500',
              body: JSON.stringify(error),
              colour: 'red',
              delay: null,
            })
          } else {
            this.toastService.show({
              body: error.error.message || JSON.stringify(error.error),
              colour: 'red',
            })
          }
        }
        return throwError(error.message);
      })
    )
  }
}
