<div class="d-md-flex justify-content-between">
  <form [formGroup]="tableFilter">
    <div class="form-group form-inline">
      <input
        style="width:18rem"
        class="form-control ml-2"
        type="text"
        name="searchCandidates"
        formControlName="search"
        placeholder="Search"
      />
    </div>
  </form>

  <div class="filters d-flex">

    <app-date-picker
      *ngIf="datepicker !== false"
      class="d-inline-block"
      (dateRange)="filterDateRange($event)"
    ></app-date-picker>

    <div
      *ngIf="userRole === 'COORDINATOR' && allAssessors.length"
      ngbDropdown
      placement="bottom-right"
      class="d-inline-block filter-dropdown"
    >
      <button
        class="btn green secondary"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        Assessors
      </button>
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic1"
      >
        <ul>
          <li *ngFor="let assessor of allAssessors">
            <label>
              <input
                type="checkbox"
                (change)="toggleAssessor(assessor)"
              >
              {{ assessor.name }}
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div
      *ngIf="allTags.length"
      ngbDropdown
      placement="bottom-right"
      class="d-inline-block filter-dropdown"
    >
      <button
        class="btn green secondary"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        Tags
      </button>
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic1"
      >
        <ul>
          <li *ngFor="let tag of allTags">
            <label>
              <input
                type="checkbox"
                (change)="toggleTag(tag)"
              >
              {{ tag }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
<p
  *ngIf="filteredBy.length"
  class="text-right ap-text-green"
>
  Filtered by: {{ filteredBy.join(', ') }}
</p>
<div #tableElement class="table-responsive">
  <table class="table table-hover mb-0">
    <thead>
      <tr>
        <th
          *ngFor="let columnHeading of submissionsTableColumnHeadings; let i = index"
          (click)="sortColumn(i)"
          tabindex="0"
          role="button"
          class="text-nowrap"
          [ngClass]="{'sortedColumn': i === sortedColumn, 'sortedColumnToggle': toggleSortOrder}"
        >
          <span>
            {{ columnHeading }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let submission of filteredSubmissions | slice: (submissionsTablePage-1) * submissionsTablePageSize : (submissionsTablePage-1) * submissionsTablePageSize + submissionsTablePageSize"
        [routerLink]="['/submission', submission.uuid]"
        [ngClass]="{
          'highlight': submission.highlight,
          'disabled': submission.step === 'assessment' && submission.status === 'validation_fail' && userRole === 'ASSESSOR'
        }"
        role="button"
        tabindex="0"
      >
        <td *ngFor="let data of submission.data; let i = index">
          <ngb-highlight
            *ngIf="i < 5"
            [result]="data"
            [term]="searchTerms"
          ></ngb-highlight>
          <ng-container
            *ngIf="i >= 5 && !displayAsDate(submission, i)"
          >
            {{ data }}
          </ng-container>
          <ng-container
            *ngIf="i >=5 && displayAsDate(submission, i)"
          >
            {{ data | date: "d/M/yyyy" }}
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-container *ngIf="filteredSubmissions.length > submissionsTablePageSize">
  <ngb-pagination
    class="d-flex justify-content-center mt-4"
    [collectionSize]="filteredSubmissions.length"
    [(pageSize)]="submissionsTablePageSize"
    [(page)]="submissionsTablePage"
    [maxSize]="5"
    [rotate]="true"
    (pageChange)="pageChange(event)"
  ></ngb-pagination>

  <p class="text-center">
    {{ (submissionsTablePage - 1) * submissionsTablePageSize + 1 }} to {{ submissionsTablePage * submissionsTablePageSize < filteredSubmissions.length ? submissionsTablePage * submissionsTablePageSize : filteredSubmissions.length }} of {{ filteredSubmissions.length }}
  </p>
</ng-container>