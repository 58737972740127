import {
  Component,
  Input,
  OnInit,
} from '@angular/core'

import {
  FileService
} from './../../services/file.service'

@Component({
  selector: 'app-written-example',
  templateUrl: './written-example.component.html',
  styleUrls: ['./written-example.component.scss'],
})

export class WrittenExampleComponent implements OnInit {

  active
  @Input() writtenExamplesData
  @Input() credentialsData

  downloadEvidenceFile(evidenceId, submissionVersion) {
    this.fileService
      .downloadEvidenceFile(evidenceId, submissionVersion)
      .subscribe((data: any) => {
        window.open(data.url)
      })
  }

  constructor(
    private fileService: FileService,
  ) {}

  sanitize(name) {
    return name.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase()
  }

  ngOnInit() {
    this.writtenExamplesData.forEach((example, exampleIndex) => {
      example.evidence.forEach((evidence, evidenceIndex) => {
        let formattedCriteria = {}

        evidence.criteria.forEach(criteria => {
          if (formattedCriteria[criteria.credential_level_id] === undefined) {
            let credentialData = this.credentialsData.find(credential => {
              return this.sanitize(criteria.name).startsWith(this.sanitize(credential.name))
            })

            formattedCriteria[criteria.credential_level_id] = {
              name: credentialData.name.replace(credentialData.level_name, '').trim(),
              criteria: [],
            }
          }
          formattedCriteria[criteria.credential_level_id].criteria.push(criteria.criteria_description)
        })

        this.writtenExamplesData[exampleIndex].evidence[evidenceIndex].formattedCriteria = formattedCriteria
      })
    })
  }

}
