<ng-template
  #content
  let-c="close"
  let-d="dismiss"
>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title"
    >
      Accept assignments
    </h4>
  </div>
  <div class="modal-body">
  	<p>
      You have been given
      {{ newSubmissions.length }}
      { newSubmissions.length,
        plural,
        =1 {assignment}
        other {assignments}
      }
      to assess. Please choose how many you will accept.
    </p>

    <form
      class="form-inline"
      [formGroup]="acceptAssignmentsForm"
    >

      <span
        class="my-1 mr-2 lead"
      >
        I accept
      </span>
      <select
        formControlName="numberOfAssignmentsSelect"
        class="custom-select custom-select-lg my-1 mr-sm-2"
      >
        <option
          *ngFor="let assessment of newSubmissions; let i = index"
        >
          {{ newSubmissions.length - i }}
        </option>
        <option>0</option>
      </select>
      <span
        class="my-1 lead"
      >
        { acceptAssignmentsForm.get('numberOfAssignmentsSelect').value,
          plural,
          =1 {assignment}
          other {assignments}
        }.
      </span>
    </form>

  </div>
  <div class="modal-footer">
    <button
      class="red"
      (click)="c()"
      *ngIf="acceptAssignmentsForm.get('numberOfAssignmentsSelect').value == 0"
    >
      { newSubmissions.length,
        plural,
        =1 {Reject assignment}
        other {Reject all assignments}
      }
    </button>
    <button
      class="green"
      (click)="c()"
      *ngIf="acceptAssignmentsForm.get('numberOfAssignmentsSelect').value != 0"
    >
      Accept
      {{ acceptAssignmentsForm.get('numberOfAssignmentsSelect').value }}
      { acceptAssignmentsForm.get('numberOfAssignmentsSelect').value,
        plural,
        =1 {assignment}
        other {assignments}
      }
    </button>
  </div>
</ng-template>