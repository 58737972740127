import { Component } from '@angular/core'
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
} from '@angular/router'

import {
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap'

import {
  AnalyticsService
} from './services/analytics.service'

import {
  UserService
} from './services/user.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private analyticsService: AnalyticsService,
    private userService: UserService,
  ) {

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        ngbModal.dismissAll()
      }

      if (event instanceof NavigationEnd) {
        this.analyticsService.addEvent({
          event: 'pageview',
          role: this.userService.getUserRole().toLowerCase(),
          page: {
            path: window.location.href + '?role=' + this.userService.getUserRole().toLowerCase(),
          }
        })
      }
    })
  }
}
