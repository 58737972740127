import { Component, OnInit }                  from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router }                             from '@angular/router'
import { AuthService }                        from './../services/auth.service'
import { UserService }                        from './../services/user.service'
import { EventService } from './../services/event.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', {
      validators: [
        Validators.email,
        Validators.required,
      ],
      updateOn: "submit",
    }),
    password: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
  })

  loginMessage

  onSubmit() {
    this.loginForm.controls.email.markAsTouched()
    this.loginForm.controls.password.markAsTouched()
    this.loginMessage = ""
    if (this.loginForm.status === "VALID") {
      this.authService
        .login(
          this.loginForm.value.email,
          this.loginForm.value.password,
        )
        .subscribe(
          result => {
            this.authService.setToken(result.access_token)
            this.userService.getUserDetails().subscribe((result: any) => {
              this.userService.setUserName(result.name)
              this.userService.setUserRole(result.roles)
              this.userService.setUserId(result.id)
              this.router.navigate(["submissions"])
            })
          },
          error => {
            this.loginMessage = this.eventService.getLoginMessage()
          }
        )
    }
  }

  ngOnInit(): void {
    this.loginMessage = this.eventService.getLoginMessage()
    this.authService.removeToken()
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private eventService: EventService,
  ) {}

}
