<div class="container">
  <div class="row">
    <div class="col-md-6 offset-md-3 my-5">

      <svg width="102" height="100" viewBox="0 0 102 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M45.578 60.3269V29H0V60.3269C0 72.8506 10.2041 83 22.789 83C35.3739 83 45.578 72.8471 45.578 60.3269Z" fill="#973D8A" fill-opacity="0.3"></path>
        <path d="M101.176 58.0129V0H16.7725V58.0129C16.7725 81.2049 35.669 100 58.9743 100C82.2796 100 101.176 81.1983 101.176 58.0129Z" fill="#973D8A"></path>
        <path d="M48.999 51.7266L43.2295 34.3086H43.1201L37.3506 51.7266H48.999ZM50.7217 57.0859H35.6006L32.5381 66H26.1396L40.6318 26.1328H45.6904L60.1826 66H53.8115L50.7217 57.0859Z" fill="white"></path>
        <path d="M70.5186 44.7266H79.46C81.7021 44.6901 83.3883 43.9974 84.5186 42.6484C85.5941 41.3359 86.1318 39.8776 86.1318 38.2734C86.1318 36.9427 85.9131 35.8216 85.4756 34.9102C85.0199 34.0898 84.3818 33.4427 83.5615 32.9688C82.3219 32.1849 80.8818 31.8112 79.2412 31.8477H70.5186V44.7266ZM64.4756 26.1328H78.9131C80.4808 26.1328 81.9938 26.2786 83.4521 26.5703C84.9105 26.9531 86.387 27.737 87.8818 28.9219C89.1396 29.8151 90.1605 31.0911 90.9443 32.75C91.7646 34.3542 92.1748 36.25 92.1748 38.4375C92.1748 41.3906 91.1357 44.0794 89.0576 46.5039C86.9248 49.056 83.8076 50.3685 79.7061 50.4414H70.5186V66H64.4756V26.1328Z" fill="white"></path>
      </svg>

      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="form-group">
          <label for="emailAddress">
            Email address
          </label>
          <input
            name="emailAddress"
            autocomplete="username"
            type="email"
            class="form-control"
            formControlName="email"
            (focus)="loginForm.get('email').markAsUntouched()"
            [ngClass]="
              {
                'is-invalid':
                  loginForm.get('email').invalid && loginForm.get('email').touched
              }"
          />
          <p class="invalid-feedback">
            Please provide a valid email address.
          </p>
        </div>

        <div class="form-group">
          <label for="password">
            Password
          </label>
          <input
            name="password"
            autocomplete="current-password"
            type="password"
            class="form-control mb-1"
            formControlName="password"
            (focus)="loginForm.get('password').markAsUntouched()"
            [ngClass]="
              {
                'is-invalid':
                  loginForm.get('password').invalid && loginForm.get('password').touched
              }"
          />
          <p class="invalid-feedback">
            Please provide a password.
          </p>
          <p class="text-right">
            <a
              routerLink="/password-reset"
            >
              <small>
                Password reset
              </small>
            </a>
          </p>
        </div>
        <button
          class="green w-100 mt-3"
          type="submit"
        >
          Log in
        </button>
        <div
          *ngIf="loginMessage"
          class="mt-3"
          [ngClass]="loginMessage.textColour"
        >
          {{ loginMessage.message }}
        </div>
      </form>
    </div>
  </div>
</div>
