import {
  Component,
  Input,
  OnInit,
} from '@angular/core'

import {
  SubmissionService,
} from './../../services/submission.service'

import {
  FileService,
} from './../../services/file.service'

import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms'

import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap'

import {
  ToastService
} from './../../services/toast.service'

import {
  EventService
} from './../../services/event.service'

import {
  UtilitiesService
} from './../../services/utilities.service'

@Component({
  selector: 'app-create-assessment',
  templateUrl: './create-assessment.component.html',
  styleUrls: ['./create-assessment.component.scss'],
})

export class CreateAssessmentComponent {

  @Input() credentials
  @Input() writtenExamples
  saving = false
  savingTimeout
  showResubmissionReason = false

  file: any = {}

  assessmentForm = new FormGroup({
    submissionIssue: new FormControl(''),
    notes: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
    rubricFile: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
    signature: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
  })

  constructor(
    private submissionService: SubmissionService,
    private fileService: FileService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private eventService: EventService,
    public utilitiesService: UtilitiesService,
  ) {}

  ngOnInit() {

    for (let x = 0; x < this.credentials.length; x++) {
      this.assessmentForm.addControl(
        'assessmentOutcome' + x,
        new FormControl('satisfied'),
      )
    }

    this.credentials.forEach((credential, credentialIndex) => {
      let testimonyFailIndex = this.credentials[credentialIndex].outcomes.indexOf('testimony_fail')
      if (testimonyFailIndex > -1) {
        this.credentials[credentialIndex].outcomes.splice(testimonyFailIndex, 1)
      }
    })

    this.assessmentForm.valueChanges.subscribe(() => {
      let outcomes = []
      this.credentials.forEach((credential, credentialIndex) => {
        outcomes.push(this.assessmentForm.controls['assessmentOutcome' + credentialIndex].value)
      })
      this.showResubmissionReason = outcomes.some(outcome => {
        return outcome === 'resubmission'
      })
    })

    this.assessmentForm.controls.rubricFile.setErrors({
      incorrect: true,
    })

    this.loadOutcome()
    this.loadFeedback()
  }

  handleFileChange(event) {
    let newFile = event.target.files[0]
    let blob = new Blob([newFile], {
      type: newFile.type,
    })
    this.file = {
      blob: blob,
      name: newFile.name,
    }
    this.assessmentForm.controls.rubricFile.setErrors(null)
  }

  saveOutcome() {
    let outcomes = []
    this.credentials.forEach((credential, credentialIndex) => {
      outcomes.push(
        this.assessmentForm.controls['assessmentOutcome' + credentialIndex].value
      )
    })

    localStorage.setItem(
      this.submissionService.getUuid() + '_outcome',
      JSON.stringify(outcomes),
    )
  }

  loadOutcome() {
    let outcomes = localStorage.getItem(this.submissionService.getUuid() + '_outcome')
    if (outcomes) {
      JSON.parse(outcomes).forEach((outcome, outcomeIndex) => {
        this.assessmentForm.patchValue({
          ['assessmentOutcome' + outcomeIndex]: outcome,
        })
      })
    }
  }

  saveFeedback(text) {

    this.saving = true

    window.clearTimeout(this.savingTimeout)

    this.savingTimeout = window.setTimeout(() => {
      this.saving = false
    }, 1000)

    localStorage.setItem(
      this.submissionService.getUuid() + '_feedback',
      text,
    )
  }

  loadFeedback() {
    let feedback = localStorage.getItem(this.submissionService.getUuid() + '_feedback')
    if (feedback) {
      this.assessmentForm.patchValue({
        notes: feedback,
      })
    }
  }

  onSubmit(content) {
    this.assessmentForm.controls.notes.markAsTouched()
    this.assessmentForm.controls.rubricFile.markAsTouched()
    this.assessmentForm.controls.signature.markAsTouched()

    this.assessmentForm.controls.submissionIssue.setErrors(null)

    let context = null

    if (this.showResubmissionReason) {
      if (this.assessmentForm.controls.submissionIssue.value === '') {
        this.assessmentForm.controls.submissionIssue.setErrors({
          incorrect: true,
        })
      } else {
        context = [this.assessmentForm.controls.submissionIssue.value]
      }
    }

    if (this.assessmentForm.status === "VALID") {

      this.modalService.open(content).result.then(() => {
        let formData = new FormData()
        formData.append("file", this.file.blob, this.file.name)
        this.fileService.uploadRubric(formData).subscribe((rubricData: any) => {

          this.credentials.forEach((credential, credentialIndex) => {

            this.submissionService.createAssessment(
              this.assessmentForm.controls['assessmentOutcome' + credentialIndex].value,
              context,
              this.assessmentForm.controls.notes.value,
              rubricData.data.id,
              this.assessmentForm.controls.signature.value,
              this.credentials[credentialIndex].id,
            ).subscribe(data => {
              this.toastService.show({
                body: 'Assessment has been successfully submitted.',
              })
              this.eventService.refreshSubmissionsData(3)
              localStorage.removeItem(this.submissionService.getUuid() + '_outcome')
              localStorage.removeItem(this.submissionService.getUuid() + '_feedback')
            })

          })

        })
      }, () => {})

    }
  }

}
