import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { SubmissionService } from './submission.service'

@Injectable({
  providedIn: 'root',
})

export class NotesService {

  constructor(
    private http: HttpClient,
    private submissionService: SubmissionService,
  ) {}

  getNotes() {
    return this.http.get(
      [
        'api',
        'submissions',
        this.submissionService.getUuid(),
        'notes',
      ].join('/')
    )
  }

  createNote(formData) {
    return this.http.post(
      [
        'api',
        'submissions',
        this.submissionService.getUuid(),
        'notes',
      ].join('/'),
      formData
    )
  }

}
