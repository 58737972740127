import {
  Component,
  OnInit,
} from '@angular/core'

import {
  SubmissionService,
} from './../services/submission.service'

import {
  UserService,
} from './../services/user.service'

import {
  UtilitiesService,
} from './../services/utilities.service'

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.scss'],
})

export class SubmissionsComponent implements OnInit {

  constructor(
    private submissionService: SubmissionService,
    private userService: UserService,
    private utilitiesService: UtilitiesService,
  ) {}

  userRole
  userId
  submissions
  inProgressSubmissions
  inProgressSubmissionsColumnHeadings = [
    'Candidate',
    'Credential/Cluster',
    'Level',
    'Instance',
    'Organisation',
    'Status',
    'Date submitted',
    'Due date',
  ]
  actionRequiredSubmissions
  completedSubmissions
  completedSubmissionsColumnHeadings = [
    'Candidate',
    'Credential/Cluster',
    'Level',
    'Instance',
    'Organisation',
    'Outcome',
  ]
  newSubmissions = []

  respondToAssignments(numberOfAcceptedAssignments) {
    this.newSubmissions.forEach(
      (newSubmission, index) => {

        let submissionIndex = this.submissions.findIndex(submission => {
          return submission.id === newSubmission.id
        })

        if (index < numberOfAcceptedAssignments) {
          this.submissions[submissionIndex].pending_action = 'provide_outcome' 
        } else {
          this.submissions.splice(submissionIndex, 1)
        }

        this.submissionService.respondToAssignment(
          newSubmission.id,
          index < numberOfAcceptedAssignments ? 'accepted' : 'rejected',
        ).subscribe(() => {})
      }
    )

    this.parseSubmissionData(this.submissions)

  }

  formatStatus(status) {
    return status.charAt(0).toUpperCase() + status.slice(1).replaceAll('_', ' ').replace('vt', 'VT')
  }

  parseSubmissionData(submissionData) {

    submissionData = submissionData.map(
      submission => {
        submission.credentials.forEach((credential, credentialIndex) => {
          submission.credentials[credentialIndex].name = this.utilitiesService.removeLevelFromCredential(
            credential.name,
            credential.level_name,
          )
        })

        if (submission.cluster) {
          submission.cluster.name = this.utilitiesService.removeLevelFromCredential(
            submission.cluster.name,
            submission.credentials[0].level_name,
          )
        }

        return submission
      }
    )

    this.newSubmissions = submissionData
      .filter(
        submission => {
          return submission.pending_action === "accept_assignment"
        }
      )

    this.inProgressSubmissions = submissionData
      .filter(
        submission => {
          return submission.status !== 'complete' && submission.pending_action !== "accept_assignment"
        }
      )
      .map(
        submission => {
          return {
            data: [
              submission.candidate.name,
              submission.cluster ? submission.cluster.name : submission.credentials[0].name,
              submission.credentials[0].level_name,
              submission.metadata.portal,
              submission.metadata.organisation,
              this.formatStatus(submission.status),
              submission.created_at,
              submission.assessors.length ? submission.assessors.slice(-1).pop().due_date : '',
            ],
            uuid: submission.id,
            highlight: submission.pending_action,
            dateFormat: [6, 7],
            tags: submission.candidate.tags,
            assessors: submission.assessors,
            status: submission.status,
            step: submission.step,
          }
        }
      )

    this.actionRequiredSubmissions = submissionData
    .filter(
      submission => {
        return submission.pending_action &&
          submission.pending_action !== "accept_assignment"
      }
    )

    this.completedSubmissions = submissionData
      .filter(
        submission => {
          return submission.status === 'complete'
        }
      )
      .map(
        submission => {
          return {
            data: [
              submission.candidate.name,
              submission.cluster ? submission.cluster.name : submission.credentials[0].name,
              submission.credentials[0].level_name,
              submission.metadata.portal,
              submission.metadata.organisation,
              submission.outcome ? submission.outcome.charAt(0).toUpperCase() + submission.outcome.substr(1) : '',
            ],
            uuid: submission.id,
            highlight: false,
            tags: submission.candidate.tags,
            assessors: submission.assessors,
          }
        }
      )
  }

  ngOnInit(): void {
    this.userRole = this.userService.getUserRole()

    this.submissionService.getSubmissions().subscribe(
      data => {
        this.submissions = data
        this.parseSubmissionData(data)
      }
    )
  }

}
