import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})

export class ToastService {

  toasts: any[] = []

  show(config) {
    config.colour = config.colour || 'green'
    config.delay = config.delay === undefined ? 5000 : config.delay
    this.toasts.push(config)
  }

  remove(toastToRemove) {
    this.toasts = this.toasts.filter(
      thisToast => thisToast != toastToRemove
    )
  }

}
