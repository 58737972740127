import { Component, Input, OnInit } from '@angular/core'
import { FileService }      from './../../services/file.service'
import { UtilitiesService } from './../../services/utilities.service'

@Component({
  selector: 'app-view-assessment',
  templateUrl: './view-assessment.component.html',
  styleUrls: ['./view-assessment.component.scss'],
})

export class ViewAssessmentComponent implements OnInit {
  
  @Input() submissionData
  assessments
  assessors
  active
  groupedAssessments = []

  constructor(
    private fileService: FileService,
    public utilitiesService: UtilitiesService,
  ) {}

  getRole(assessment) {
    return this.assessors.find(assessor => {
      return assessor.id === assessment.owner.id
    }).role
  }

  openRubricFile(id) {
    this.fileService
      .downloadAssessmentRubric(id)
      .subscribe((data: any) => {
        window.open(data.url)
      })
  }

  getCredentialName(level) {
    return this.submissionData.credentials.find(credential => {
      return credential.id == level
    }).name
  }

  ngOnInit() {
    this.assessments = this.submissionData.assessments
    this.assessors = this.submissionData.assessors

    let assessmentsGroupedByRubricId = {}
    this.assessments.forEach(assessment => {

      if (assessment.rubric) {

        if (assessmentsGroupedByRubricId[assessment.rubric.id] === undefined) {
          assessmentsGroupedByRubricId[assessment.rubric.id] = []
        }

        assessmentsGroupedByRubricId[assessment.rubric.id].push(assessment)

      } else if (assessment.outcome === 'testimony_fail') {

        if (assessmentsGroupedByRubricId['testimonyFail'] === undefined) {
          assessmentsGroupedByRubricId['testimonyFail'] = []
        }

        switch (assessment.metadata.substr(-2, 2)) {
          case 'we':
            assessment.testimony_fail_reason = 'Written example'
            break;
          case 'vt':
            assessment.testimony_fail_reason = 'Video testimony'
        }

        assessmentsGroupedByRubricId['testimonyFail'].push(assessment)

      }

    })

    for (const property in assessmentsGroupedByRubricId) {
      this.groupedAssessments.push(assessmentsGroupedByRubricId[property])
    }

    this.groupedAssessments.sort((a, b) => {
      let dateA = new Date(a[0].created_at).getTime()
      let dateB = new Date(b[0].created_at).getTime()
      if (dateA > dateB) return -1
      if (dateA < dateB) return  1
      return 0
    })

  }

}
