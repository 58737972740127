<app-toast
  aria-live="polite"
  aria-atomic="true"
></app-toast>

<div
  class="d-flex flex-column h-100"
>
  <main
    class="flex-shrink-0 mb-5"
  >

    <app-nav>
    </app-nav>

    <router-outlet>
    </router-outlet>

  </main>

  <app-footer
    class="mt-auto"
  >
  </app-footer>

</div>
