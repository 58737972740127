import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class VersionService {

  getVersion() {
    return this.http.get('api/version');
  }

  constructor(
  	private http: HttpClient
  ) {}

}
