<div class="row">
  <div
    class="col-md-6"
    *ngIf="userRole === 'COORDINATOR'"
  >
    <form
      [formGroup]="notesForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-group">
        <label for="notes">
          Note
        </label>
        <textarea
          class="form-control"
          id="notes"
          rows="10"
          formControlName="notes"
          (focus)="notesForm.get('notes').markAsUntouched()"
          [ngClass]="
            {'is-invalid':
              notesForm.get('notes').invalid && notesForm.get('notes').touched
            }"
        ></textarea>
        <p class="invalid-feedback">
          Please provide note.
        </p>
      </div>
      <div class="form-group">
        <label>
          Optional file
        </label>
        <div class="custom-file">
          <input
            name="supportingFile"
            id="supportingFile"
            type="file"
            class="custom-file-input"
            formControlName="supportingFile"
            (change)="handleFileChange($event)"
          />
          <label
            for="supportingFile"
            class="custom-file-label"
          >
            {{ file.name }}
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="form-check">
          <input
            type="checkbox"
            id="visibleToAssessors"
            formControlName="visibleToAssessors"
            class="form-check-input"
          >
          <label
            class="form-check-label"
            for="visibleToAssessors"
          >
            Visible to assessors
          </label>
        </div>
      </div>
      <button
        class="green"
        type="submit"
      >
        Submit note
      </button>
    </form>
  </div>
  <div
    class="col-md-6"
    *ngIf="notesData"
  >
    <div
      class="note"
      *ngFor="let note of notesData"
    >
      <p>
        {{ note.created_by.name }}
        <br>
        {{ note.created_at | date: 'short' }}
      </p>
      <p>
        {{ note.notes }}
      </p>
      <button
        (click)="downloadFile(note.id)"
        *ngIf="note.file"
        class="button grey secondary"
      >
        Download file
      </button>
    </div>
  </div>
</div>



































