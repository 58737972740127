<p
  *ngFor="let assessment of assessments"
  [ngClass]="utilitiesService.getOutcomeClass(assessment.outcome)"
  class="h4"
>
  {{ assessment.outcome.replaceAll('_', ' ').toUpperCase() }}
  <span
    *ngIf="assessments.length > 1"
    class="ap-text-grey"
  >
    – {{ assessment.credential }}
  </span>
</p>

<p
  *ngIf="assessments.length === 0"
  class="h4 ap-text-green"
>
  PENDING
</p>

<p class="h5 ap-text-green">
  {{ assessor.name }}
</p>

<p
  *ngIf="assessments.length"
>
  Date submitted: {{ assessments[0].created_at | date: "d/M/yyyy" }}
</p>

<p
  *ngIf="!assessments.length && assessor.due_date"
>
  Due date: {{ assessor.due_date | date: "d/M/yyyy" }}
</p>

<button
  *ngIf="assessments.length"
  [ngClass]="isArchiveDisabled() ? 'disabledButton' : 'grey secondary'"
  [disabled]="isArchiveDisabled()"
  (click)="open(content)"
>
  Archive outcome
</button>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Archive outcome
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form
    [formGroup]="archiveOutcomeForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="modal-body">
      <div class="form-group">
        <label for="archiveReason">
          Archive reason
        </label>
        <textarea
          class="form-control"
          id="archiveReason"
          rows="10"
          formControlName="archiveReason"
          (focus)="archiveOutcomeForm.get('archiveReason').markAsUntouched()"
          [ngClass]="
            {'is-invalid':
              archiveOutcomeForm.get('archiveReason').invalid && archiveOutcomeForm.get('archiveReason').touched
            }"
        ></textarea>
        <p class="invalid-feedback">
          Please provide archive reason.
        </p>
      </div>
      <div class="form-group">
        <label for="signature">
          Please sign your name below to confirm archive outcome
        </label>
        <input
          class="form-control"
          type="text"
          name="signature"
          formControlName="signature"
          placeholder="Coordinator name"
          (focus)="archiveOutcomeForm.get('signature').markAsUntouched()"
          [ngClass]="
            {'is-invalid':
              archiveOutcomeForm.get('signature').invalid && archiveOutcomeForm.get('signature').touched
            }"
        />
        <p class="invalid-feedback">
          Please provide your signature.
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <button
        ngbAutofocus
        class="red secondary"
        (click)="modal.dismiss()"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="green"
      >
        Submit
      </button>
    </div>
  </form>
</ng-template>
