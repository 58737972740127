import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'

import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms'

import {
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap'

import {
  SubmissionService,
} from './../../../services/submission.service'

import {
  ToastService
} from './../../../services/toast.service'

@Component({
  selector: 'app-assign-assessor',
  templateUrl: './assign-assessor.component.html',
  styleUrls: ['./assign-assessor.component.scss'],
})

export class AssignAssessorComponent {

  @Input() assessors
  @Input() oppositeAssessor
  @Input() role
  @Output() assignedAssessor = new EventEmitter

  assignAssessorForm = new FormGroup({
    selectAssessor: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: "submit",
    }),
  })

  constructor(
    private modalService: NgbModal,
    private submissionService: SubmissionService,
    private toastService: ToastService,
  ) {}

  openAssignAssessorConfirmationModal(content) {
    this.assignAssessorForm.controls.selectAssessor.markAsTouched()
    if (this.assignAssessorForm.status !== 'INVALID') {
      this.modalService.open(content).result.then(() => {
        this.submissionService.assignAssessor(
          this.role,
          this.assignAssessorForm.get('selectAssessor').value.id,
        ).subscribe(
          result => {
            this.assignedAssessor.emit(
              this.assignAssessorForm.get('selectAssessor').value.id,
            )
            this.toastService.show({
              body: [
                'Successfully assigned',
                this.assignAssessorForm.get('selectAssessor').value.name,
                'as the',
                this.role,
                'assessor.',
              ].join(' ')
            })
          },
          error => {}
        )
      }, () => {})
    }
  }

}
