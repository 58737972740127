<ng-container *ngIf="submissions">

  <app-accept-assignments-modal
    *ngIf="userRole == 'ASSESSOR' && newSubmissions.length"
    [newSubmissions]="newSubmissions"
    (acceptedSubmissions)="respondToAssignments($event)"
  >
  </app-accept-assignments-modal>

  <div class="container">
    <div class="row mb-5 d-none d-md-flex" *ngIf="actionRequiredSubmissions && actionRequiredSubmissions.length > 0">
      <div class="col-12">
        <p class="h5">Action required</p>
      </div>

      <app-action-required-card
        class="col-12"
        [actionRequiredSubmissions]="actionRequiredSubmissions"
      >
      </app-action-required-card>

    </div>

    <div class="row">
      <div class="col-12">
        <ul
          ngbNav
          #nav="ngbNav"
          class="nav-tabs mb-3 position-relative"
        >
          <li ngbNavItem>
            <a ngbNavLink>In progress</a>
            <ng-template ngbNavContent>
              <app-submissions-table
                [submissionsTableColumnHeadings]="inProgressSubmissionsColumnHeadings"
                [submissionsTableData]="inProgressSubmissions"
              ></app-submissions-table>
            </ng-template>
          </li>
          <li ngbNavItem *ngIf="completedSubmissions.length">
            <a ngbNavLink>Completed</a>
            <ng-template ngbNavContent>
              <app-submissions-table
                [submissionsTableColumnHeadings]="completedSubmissionsColumnHeadings"
                [submissionsTableData]="completedSubmissions"
                [datepicker]=false
              ></app-submissions-table>
            </ng-template>
          </li>
        </ul>
        <app-invoice
          class="invoice"
          [submissionsData]="submissions"
        ></app-invoice>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="!submissions">
</app-loader>
