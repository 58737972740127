import {
  Injectable,
} from '@angular/core'

import {
  Observable,
  Subject,
} from 'rxjs'

@Injectable(
  {
    providedIn: 'root',
  }
)

export class EventService {
  refreshSubmissions = new Subject<any>()

  refreshSubmissionsData(switchToTab = null) {
    this.refreshSubmissions.next(switchToTab)
  }

  onRefreshSubmissionsData(): Observable<any> {
    return this.refreshSubmissions.asObservable()
  }

  refreshVideoTestimony = new Subject<any>()

  refreshVideoTestimonyData() {
    this.refreshVideoTestimony.next()
  }

  onRefreshVideoTestimonyData(): Observable<any> {
    return this.refreshVideoTestimony.asObservable()
  }

  loginMessage = null

  setLoginMessage(message, textColour) {
    this.loginMessage = {
      message: message,
      textColour: textColour,
    }
  }

  getLoginMessage() {
    let message = this.loginMessage
    this.loginMessage = null
    return message
  }

}
