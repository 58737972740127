<div class="row mb-5">
  <div class="col-md-2">
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="active"
      class="nav-pills"
      orientation="vertical"
    >
      <ng-container
        *ngIf="lastCompletedTestimony"
      >
        <li
          *ngFor="let question of lastCompletedTestimony.questions; let i = index"
          [ngbNavItem]="i"
        >
          <a
            ngbNavLink
            class="button grey secondary text-center mb-3"
          >
            Question {{ i + 1 }}
          </a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12">
                <p
                  class="video-question"
                >
                  {{ question.title }}
                </p>
                <video
                  *ngIf="videoUrls[i]"
                  controls
                  class="w-100"
                  autoplay
                  (ended)="videoEnd()"
                >
                  <source
                    src="{{ videoUrls[i] }}"
                    type="video/webm"
                  >
                </video>
              </div>
            </div>
          </ng-template>
        </li>
      </ng-container>
      <li
        ngbNavItem
        *ngIf="userRole === 'COORDINATOR' && videoTestimonyLog.length"
      >
        <a
          ngbNavLink
          class="button grey secondary text-center mb-3"
        >
          Sessions log
        </a>
        <ng-template ngbNavContent>
          <div
            class="mb-5"
            *ngFor="let session of videoTestimonyLog"
          >
            <p *ngIf="!session.error">
              {{ session.status }}
              <br>
              Created at: {{ session.created_at | date: "d/M/yyyy" }}
              <br>
              Due at: {{ session.due_at | date: "d/M/yyyy" }}
            </p>
            <p *ngIf="session.error" class="ap-text-red">
              {{ session.error }}
              <br>
              Created at: {{ session.created_at | date: "d/M/yyyy" }}
            </p>
            <p>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="col-md-10">
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>

<button
  class="yellow"
  (click)="createVtSession()"
  *ngIf="userRole === 'COORDINATOR'"
>
  Create VT session
</button>